.Authentication{
     .test-box{
          width: 100%;
          height: 5rem;
          position: absolute;
          background: #fff;
          left:0.5rem;
          top:0.1rem;
          .test-back{
               display: flex;
               align-items: center;
               font-size: 0.16rem;
               font-family: OPPOSans-M, OPPOSans;
               font-weight: normal;
               color: #001420;
               margin-bottom: 0.5rem;
               transform:scale(1.5);
               transform-origin: 0% 0%;
          }
          .test-title{
               font-size: 0.24rem;
               font-family: OPPOSans-M, OPPOSans;
               font-weight: normal;
               color: #001420;
               margin-bottom: 0.2rem;
          }
          p{
               font-size: 0.16rem;
               font-family: OPPOSans-R, OPPOSans;
               font-weight: normal;
               color: #aaa;
               margin-bottom: 0.3rem;
          }
          .test-describe{
               font-size: 0.14rem;
               font-family: OPPOSans-M, OPPOSans;
               font-weight: normal;
               color: #001420;
               margin-bottom: 0.1rem;
          }
          .message-test-box{
               width: 3.6rem;
               height: 0.55rem;
               border-radius: 0.08rem;
               margin-bottom: 0.6rem;
               position: relative;
            
               .phone-label{
                  font-size: 0.18rem;
                  font-family: 'OPPOSans-M, OPPOSans';
                  font-weight: normal;
                  color: #001420;
               }
               .message-test{
                   width: 3.6rem;
                   height: 0.55rem;
                   background: #F0F1F4;
                   border-radius: 0.08rem;
                   position: absolute;
  
               }
               .link-button{
                     position: absolute;
                     right: 0rem;
                     top:0.13rem;
               }
          } 
          .button-ok{
               width: 1.2rem;
               height: 0.5rem;
               border-radius: 0.08rem;
               position: relative;
               bottom: 1rem;
          }
      }
}
  



