.selected-series {
    margin-top: 80px;

    .selected-series-title {
        font-size: 40px;
        color: #001420;
    }


    .selected-series-list {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        margin-top: 0.3rem;

        .selected-series-card {
            width: 392px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 12px;
            border: 1px solid #E4E4E4;
            box-shadow: 0px 2px 20px 0px rgba(0, 20, 32, 0.08);
            position: relative;
            text-align: center;

            // overflow: hidden;
            .selected-series-background {

                width: 100%;
                height: 150px;

                >img {
                   border-radius: 12px 12px 0 0;
                    width: 100%;
                    height: 150px;
                    display: block;
                }
            }

            .selected-series-avatar {
                position: absolute;
                top: 108px;
                right: 150px;
                width: 82px;
                height: 82px;
                border-radius: 50%;
                border: 4px solid #fff;
            }

            .selected-series-title {
                font-size: 16px;
                margin-top: 50px;
                color: #001420;
                text-align: center;
            }

            .star-number {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: normal;
                color: #000000;
                margin-top: 13px;
            
            }
        }

        .selected-series-card:hover {
            transform: translateY(-10px);
        }

        .featured-Collection-card {
            width: 392px;
            height: 300px;
            background: #FFFFFF;
            box-shadow: 0px 2px 20px 0px rgba(0, 20, 32, 0.08);
            border-radius: 12px;
            border: 1px solid #E4E4E4;

            .back-img {
                img {
                    width: 100%;
                    height: 140px;
                }
            }
        }
    }

    .attention {
        margin-left: 8px;
    }
}