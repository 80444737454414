.WorksSeries{
    width: 100%;
    position: absolute;
    left: 55px;
    top:100px;
    padding-bottom: 100px;
    .CreateSeries-back{
        height: 21px;
        position: relative;
        font-size: 16px;
        background: #fff;
        top:-90px;
        width: 150px;
        height: 50px;
        font-family: OPPOSans-M, OPPOSans;
        font-weight: normal;
        color: #001420;  
        display: flex;
        align-items: center;
    }
    .header-table-box{
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: space-between;
        border-bottom:1px solid #eee; 
        align-items: center;
        .header-table{
             display: flex;
             width: 100%;
             padding-left: 0px;
             padding: 4px;
             justify-content: space-between;
             align-items: center;
             margin-left: 0px;
             width: 700px;
             div{
                width: 90px;
                padding: 10px;
                text-align: center;    
                height: 46px;
                padding: 6px;
                font-size: 20px;
                font-family: OPPOSans-B, OPPOSans;
                font-weight: normal;
                color: #bbb;
                margin-top: 22px;
                // margin-right: 50px;
             }
             .active{
                border-bottom: 3px solid #00A2FD;
                color: #001420;
             }
        }
     }
    .worksAdmin-box{
        width: 100%;
       .dl{ 
           padding-top: 20px;
           padding-left: 30px;
           padding-bottom: 30px;
           display: flex;
           .works-Avatar{
                width: 319px;
                height: 240px;
                background: #FFFFFF;
                box-shadow: 0px 2px 20px 0px rgba(0,20,32,0.08);
                border-radius: 12px;
                border: 1px solid #E4E4E4;
           }
           .works-content-box{
               margin-left: 20px;
               .title{
                        font-size: 20px;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #001420;
               }
               .eye-love{
                    display: flex;
                    margin-top: 8px;
                   .eye{
                       display: flex;
                       align-items: center;
                       font-size: 12px;
                       font-family: OPPOSans-M, OPPOSans;
                       font-weight: normal;
                       color: #001420;
                       margin-right: 30px;
                   }
                   .love{
                       display: flex;
                       align-items: center;
                       font-size: 12px;
                       font-family: OPPOSans-M, OPPOSans;
                       font-weight: normal;
                       color: #001420;
                       margin-right: 30px;
                   }
                   .date{
                        font-size: 12px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #888;
                   }
               }
               .ycs{
                     .skybulue{
                       height: 16px;
                       font-size: 12px;
                       font-family: OPPOSans-M, OPPOSans;
                       font-weight: normal;
                       color: #008EFA;
                     }
                     .date{
                       font-size: 12px;
                       font-family: OPPOSans-M, OPPOSans;
                       font-weight: normal;
                       color: #888;
                       margin-left: 20px;
                     }
               }
               .collection-all-count{
                     line-height: 50px;
               }
               .works-content-status{
                      width: 400px;
                      margin-top: 100px;
                      display: flex;
                      align-items: center;
                      a{
                        font-size: 16px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #888;
                        margin-right: 40px;
                      }
               }
           }
           
       }
       dl:hover{
             background: #eee; 
       }
       .create-button-box{
       
        height: 130px;
        background: #FAFAFA;
        border-radius: 4px;
        font-size: 14px;
        font-family: OPPOSans-M, OPPOSans;
        font-weight: normal;
        color: #bbb;
        text-align: center;
        line-height: 130px;
        margin: 40px 0;

    }
    #none{
        display: none;
   }
 }
}

