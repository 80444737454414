.content-auth-modal {
    .title {
        text-align: center;
        font-size: 24px;
        color: #001420;

    }

    .subtitle {
        text-align: center;
        font-size: 16px;
        color: #93989A;
        margin-top: 14px;
    }
    .qr-code-area {
        text-align: center;
        margin-top: 26px;
    }
    .auth-status {
        text-align: center;
        font-size: 16px;
        color: #93989A;
        margin-top: 14px;
    }
    .auth-status-tag {
        margin-bottom: 12px;
    }

}