.selected-works {

    margin-top: 80px;

    .selected-works-title {
        font-size: 40px;
        color: #001420;
    }

    .selected-works-describe {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .works-describe-left {
            font-size: 20px;
            color: #001420;
        }

        .works-describe-right {
            font-size: 20px;

            a {
                text-decoration: none;
                color: #202020;
            }
        }
    }

    .works-banner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 31px;

        .work-card {
            width: 286px;
            height: 417px;
            background: #FFFFFF;
            border-radius: 15px;
            border: 1px solid #E4E4E4;
            position: relative;

            .works-img-item {

                // position: relative;
                // z-index: 10000;
                img {
                    width: 100%;
                    height: 286px;
                    border-radius: 15px 15px 0 0;
                    font-size: 24px;
                }
            }

            .card-describe {
                padding: 20px 20px 0 20px;

                .name {
                    font-size: 16px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .dec {
                    font-size: 12px;
                    margin-top: 6px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .footer {
                    font-size: 16px;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 26px;

                    >div:nth-child(2) {
                        display: flex;
                        align-items: center;

                        >span:nth-child(2) {
                            margin-left: 8px;
                        }
                    }
                }
            }

            .buy-button {
                width: 100px;
                height: 40px;
                background: #008EFA;
                border-radius: 8px;
                border: 1px solid #008EFA;
                font-size: 14px;
                color: #fff;
                text-align: center;
                line-height: 40px;
                position: absolute;
                top: 222px;
                left: 93px;
                display: none;
                z-index: 1000;
                cursor: pointer;
            }

            .sale-status {
                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 12px;
                width: 60px;
                background-color: rgba(7, 7, 7, 0.7);
                text-align: center;
                height: 28px;
                line-height: 28px;
                border-radius: 22px;
                color: #fff;
                display: none;
                z-index: 1000;
            }
        }

        .work-card {
            box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);

        }

        .work-card:hover {
            transform: translateY(-10px);
        }

        .work-card:hover .avatar-mask {
            display: block;
        }

        .work-card:hover .buy-button,
        .work-card:hover .sale-status {
            display: block;
        }

        .avatar-mask {
            position: absolute;
            z-index: 5;
            top: 0px;
            right: 0px;
            width: 100%;
            // width: 286px;
            height: 286px;
            background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.17) 100%);
            border-radius: 15px;
            opacity: 0.3;
            display: none;
        }


    }


}