.Market {
    width: 100%;
    height: 1000px;

    .market-content {

        width: 1240px;
        height: 1000px;
        margin: 0 auto;
        margin-top: 50px;

        main {
            width: 100%;

            .selects {
                width: 550px;
                display: flex;
                justify-content: space-between;
            }

            .content {
                margin-top: 30px;
                width: 100%;

                .noStatusBack {
                    position: relative;
                    left: 50%;
                    top: 300px;
                }


                .ant-btn {
                    font-size: 18px;
                    line-height: normal;
                    width: 180px;
                    height: 57px;
                    line-height: 57px;
                }

                .work-card {
                    width: 286px;
                    height: 417px;
                    background: #FFFFFF;
                    border-radius: 15px;
                    border: 1px solid #E4E4E4;
                    position: relative;

                    .works-img-item {
                        img {
                            width: 100%;
                            height: 286px;
                            border-radius: 15px 15px 0 0;
                            font-size: 24px;
                        }
                    }

                    .card-describe {
                        padding: 20px 20px 0 20px;

                        .name {
                            font-size: 16px;                           
                        }

                        .dec {
                            font-size: 12px;
                            margin-top: 6px;
                        }

                        .name, .dec {
                            white-space:nowrap; 
                            overflow:hidden; 
                            text-overflow:ellipsis;
                        }

                        .footer {
                            font-size: 16px;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 26px;

                            >div:nth-child(2) {
                                display: flex;
                                align-items: center;

                                >span:nth-child(2) {
                                    margin-left: 8px;
                                }
                            }
                        }
                    }

                    .buy-button {
                        width: 100px;
                        height: 40px;
                        background: #008EFA;
                        border-radius: 8px;
                        border: 1px solid #008EFA;
                        font-size: 14px;
                        color: #fff;
                        text-align: center;
                        line-height: 40px;
                        position: absolute;
                        top: 222px;
                        left: 93px;
                        display: none;
                        z-index: 1000;
                        cursor: pointer;
                    }

                    .sale-status {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        font-size: 12px;
                        width: 60px;
                        background-color: rgba(7, 7, 7, 0.7);
                        text-align: center;
                        height: 28px;
                        line-height: 28px;
                        border-radius: 22px;
                        color: #fff;
                        display: none;
                        z-index: 1000;
                    }
                }

                .work-card {
                    box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);

                }

                .work-card:hover {
                    transform: translateY(-10px);
                }

                .work-card:hover .avatar-mask {
                    display: block;
                }

                .work-card:hover .buy-button,
                .work-card:hover .sale-status {
                    display: block;
                }

                .avatar-mask {
                    position: absolute;
                    z-index: 5;
                    top: 0px;
                    right: 0px;
                    width: 100%;
                    height: 286px;
                    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.17) 100%);
                    border-radius: 15px;
                    opacity: 0.3;
                    display: none;
                }
            }

            .load-more-button {
                width: 180px;
                height: 57px;
                line-height: 57px;
                border-radius: 8px;
                border: 2px solid #008EFA;
                text-align: center;

                font-size: 18px;
                color: #008EFA;
                background: #fff;

            }
        }
    }
}