.FansPage{
    width: 90%;
    position: absolute;
    left: 55px;
    top:60px;
    padding-bottom: 100px;
    .bill-list-box{
        margin-top: 40px;
       .bill-list-title{
             display: flex;
             width: 100%;
             font-size: 14px;
             font-family: OPPOSans-M, OPPOSans;
             font-weight: normal;
             color: #001420;
             border-top: 1px solid #eee;
             padding-left: 20px;
             li{
                   height: 50px;
                   line-height: 50px;
                   font-size: 14px;
                   font-family: OPPOSans-M, OPPOSans;
                   font-weight: normal;
                   color: #001420;
             }
             .one{
                  width: 30%;
             }
             .two{
                  width: 52%;
             }
       }
       .bill-list-card{
             width: 100%;
             margin-left: -35px;
             padding-left: 30px;
             margin-top: -80px;
             .noStatusBack{
                  position: relative;
                  left: 0;
                  top:300px;
              }
             li{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    height: 80px;
                    padding-left:10px;
                    font-size: 12px;
                   .list-card-one{
                        display: flex;
                        align-items: center;
                        padding-right: 50px;
                        width: 30%;
                        .type-icon{
                            width: 62px;
                            height: 62px;
                        }
                        .card-one-box{
                              margin-left: 10px;
                             .card-one-title{
                               font-size: 14px;
                               font-family: OPPOSans-M, OPPOSans;
                               font-weight: normal;
                               color: #001420;
                             }
                             .card-one-describe{
                               font-size: 12px;
                               font-family: OPPOSans-R, OPPOSans;
                               font-weight: normal;
                               color: #001420;
                             }
                        }
                   }
                   .list-card-two{
                       width: 450px;
                   }
                   .list-card-three{
                        width: 250px;
                        text-align: center;
                   }
             }
             li:hover{
                 background: #F8F9F9;
               //   margin-right: 100px;
             }
       }
 }
}

