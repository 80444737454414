header {
    filter: drop- shadow(0rem 0.2rem 0.1remrgba(255, 255, 255, 0.8));
    width: 100%;

    .header-bar {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 64px;
        background: #fff;

        .ant-badge-count {
            text-align: center;
            transform: scale();

            .ant-scroll-number {}
        }

        .header-bar-left {
            display: flex;
            align-items: center;

            .logo {
                width: 200px;
                height: 40px;
                cursor: pointer;
            }
        }

        .header-bar-right {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .menu-nav {
                display: flex;
            }

            .child {
                font-size: 20px;
                color: #253043;
                margin-right: 60px;
                font-size: 500;

            }

            .child:last-of-type {
                margin-right: 0px;
            }


            .child:hover {
                color: #008EFA;
            }

            .headerName {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 54px;
                height: 54px;


                .header-avatar {
                    width: 44px;
                    height: 44px;
                }

                .select_mather {
                    display: none;
                }

                a {
                    #login-avatar {
                        width: 44px;
                        height: 44px;
                    }
                }

            }

            .headerName:hover {
                border: 1px solid #CCCCCC;
                border-radius: 50%;
            }



        }
    }

    .notice {
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 47px;
        cursor: pointer;
    }

    .unread {
        .ant-badge-count {
            display: block;
        }

    }

    .read-active {
        .ant-badge-count {
            display: none;
        }
       
    }

    .notice:hover {
        border-radius: 50%;
        border: 1px solid #CCCCCC;
    }
}

.notice-popover {
    .ant-popover-arrow {
        display: none !important;
    }

    .ant-popover-title {
        background: #F6F6F6;
        font-size: 16px;
        padding: 10px 0px 12px 20px !important;
        border: 0 !important;
    }

    .ant-popover-content {
        width: 305px;
    }

    .ant-popover-inner {
        border-radius: 8px;
    }

}

.mine-popover {
    .ant-popover-arrow {
        display: none !important;
    }

    .ant-popover-content {
        width: 172px;

    }

    .ant-popover-inner {
        height: 334px !important;
        border-radius: 6px;
    }
}



.notice-item {
    margin-left: 12px;
    font-size: 12px;

    >div:nth-child(1),
    >div:nth-child(2) {
        color: #001420;
    }


    >div:nth-child(3) {
        color: #8f8f8f;
        margin-top: 5px;
    }
}

.ant-list-item-meta-avatar {
    margin-right: 0px !important;
}

.noticeContent {}

.operation {
    font-size: 12px;
    display: flex;

    .left {
        width: 40px;
        height: 40px;
        background: rgba(0, 142, 250, 0.1);
        border: 1px solid #008EFA;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        // height: 16px;
        color: #008EFA;
    }

    .right {
        margin-left: 12px;

        >div:nth-child(1) {
            color: #001420;
        }

        >div:nth-child(2) {

            color: #008EFA;
        }

        >div:nth-child(3) {
            color: #8f8f8f;
            margin-top: 5px;
        }
    }
}


.select_mather {
    height: 100%;

    .select_child {
        width: 100%;
        height: 39px;
        line-height: 39px;
        text-align: left;
        padding-left: 12px;
        font-size: 16px;
        margin-bottom: 4px;
        cursor: pointer
    }

    .select_child:hover {
        background: #008EFA;
        color: #fff;
        border-radius: 6px;

    }

    .last_child {
        text-align: left;
    }
}