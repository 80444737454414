.popularity-creator-box {
    margin-top: 80px;

    .popularity-creator {
        font-size: 40px;
        color: #001420;
    }

    .works-banner {
        position: relative;
        // height: 4.27rem;
        margin-top: 40px;

        .ant-carousel {

            //    height: 3.97rem;
            .slick-slider {
                .slick-list {
                    //  height: 4.27rem;

                }
            }
        }

        .ant-carousel .slick-dots {
            z-index: -1;
        }

        #backLeft {
            position: absolute;
            left: -32px;
            top: 15px;
            width: 72px;
            height: 72px;
            display: none;
            .backLeft-avartar {
                width: 100%;
                height: 100%;
            }
        }

        #bakRight {
            position: absolute;
            width: 72px;
            height: 72px;
            right: -32px;
            top: 15px;
            display: none;
            .bakRight-avartar {
                width: 100%;
                height: 100%;
            }
        }
        .noIcon {
            display: none !important;
        }
        .yesIcon {
            display: block !important;
        }
    }

    .works-banner:hover {
        #backLeft {
            display: block;
        }

        #bakRight {
            display: block;
        }
    }

    .popularity-list-box {
        // display: flex;
        // justify-content: space-between;
        Row {
            width: 100%;
        }
        .popularity-card {
            width: 180px;
            text-align: center;
            .popularity-avatar-box {
                .popularity-avatar {
                    width: 180px;
                    height: 180px;
                }
                .popularity-label {
                    width: 96px;
                    height: 36px;
                    background-color: rgba(7, 7, 7, 0.7);
                    border-radius: 24px;
                    font-size: 14px;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    top: -20px;
                    margin: auto;

                }
            }

            .popularity-item {
                font-size: 16px;
                color: #000000;
                display: flex;
                justify-content: center;
                // margin-top: 14px;
                align-items: center;

                img {
                    width: 14px;
                    height: 14px;
                    margin-left: 6px;
                }
            }

            .popularity-describe {
                font-size: 14px;
                color: #001420;
                margin-top: 14px;
                white-space: nowrap; // 强制一行显示
                overflow: hidden; // 超出隐藏
                text-overflow: ellipsis; // 省略号   
            }
        }
    }
}

