
.CreateWorks{
    position: relative;
    width: 600px;
    left: 0px;
    top: -1220px;
    background: #fff;
    .CreateSeries-title{
        font-size: 24px;
        font-family: OPPOSans-M, OPPOSans;
        font-weight: normal;
        color: #001420;
        margin: 30px 0;
    }
    .CreateWorks-form{
         margin-left: 180px;
        .ant-form-item{
            margin-bottom: 50px;
            margin-left:-320px;
        }
        .submit{
            width: 250px;
            height: 50px;
            background: #008EFA;
            border-radius: 8px;
        }
        .ant-col-8 {
            margin-right: 40px;
            width: 80px;
            font-size: 30px; 
            font-weight: normal;
            color: #001420;
        }
        .ant-form-item-label>label{
            width: 100px!important;
            height: 26px;
            font-size: 20px;
            font-family: OPPOSans-M;
            font-weight: normal;
            color: #001420;
            line-height: 26px;
        }
        #basic_username{
            width: 500px;
            height: 60px;
            background: #F0F1F4;
            border-radius: 8px;
            padding-left: 20px;
        }
        .ant-form-horizontal .ant-form-item-control{
             margin-left: 50px;
        }
        .zhuzao-box{
              width: 100%;
              .zhuzao-child-one{
                    display: flex;
                    width: 150px;
                    justify-content: space-between;
                    .zhuzao-label{
                        font-size: 14px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                    }
                    .zhuzao-content{
                        font-size: 14px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                    }
              }
              .zhuzao-child-two{
                    display: flex;
                    width: 150px;
                    justify-content: space-between;
                    margin:10px 0;
                   .zhuzao-label{
                        font-size: 14px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                   }
                   .zhuzao-content{
                        font-size: 14px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                       
                   }
              }
              .zhuzao-child-three{
                font-size: 14px;
                font-family: 'OPPOSans-M, OPPOSans';
                font-weight: normal;
                color: #999;
              }
        }
        .createWorks-face-box{
            width: 281px;
            height: 187px;
            background: #F6F6F6;
            border-radius: 4px;
            border: 1px solid #EBEBEB;
            color: #008EFA;
            font-size: 24px;
            line-height: 187px;
            text-align: center;
        }
        .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before{
            display: none;
      }
    }
}