.WorksAdmin{
    width: 100%;
    position: absolute;
    left: 50px;
    top:50px;
    padding-bottom: 100px;
    .works-admin-title-box{
         display: flex;
         width: 90%;
         height: 50px;
         justify-content: space-between;
        .CreateSeries-back{
            height: 21px;
            position: relative;
            font-size: 16px;
            background: #fff;
            top:-90px;
            width: 150px;
            height: 50px;
            font-family: OPPOSans-M, OPPOSans;
            font-weight: normal;
            color: #001420;  
            display: flex;
            align-items: center;
        }
        .worksadmin-right-explain{
            font-size: 16px;
            font-family: OPPOSans-M, OPPOSans;
            font-weight: normal;
            color: #001420;
            display: flex;
        }
    }
    
    .header-table-box{
        width: 90%;
        height: 59px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom:1px solid #eee; 
        .header-table{
             display: flex;
            justify-content: space-between;
             padding-left: 0px;
             align-items: center;
             width: 400px;
             height: 36px;
             a{
                width: 150px;
                text-align: center;    
                height: 36px;
                font-size: 16px;
                font-family: OPPOSans-B, OPPOSans;
                font-weight: normal;
                color: #bbb;
                margin-top: 22px;
                border-bottom: 3px solid #fff;
                margin-right: 20px;
             }
             .active{
                border-bottom: 3px solid #00A2FD;
                color: #001420;
             }
        }
        .right-allcount{
            font-size: 14px;
            font-family: OPPOSans-R, OPPOSans;
            font-weight: normal;
            color: #93989A;
        }
     }
     .selects{
        width: 250px;
        line-height: 40px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .worksAdmin-box{
        width: 90%;
        height:1100px;
        overflow-y: scroll;
        margin-top: 20px;
       .dl{ 
           padding-top: 20px;
           padding-left: 30px;
           height: 242px;
           border-radius: 8px;
           padding-bottom: 30px;
           display: flex;
           .works-Avatar{
               width: 150px;
               height: 200px;
           }
           .works-content-box{
               margin-left: 20px;
               .title{
                   font-size: 16px;
                   font-family: OPPOSans-M, OPPOSans;
                   font-weight: normal;
                   color: #001420;
               }
               .eye-love{
                    display: flex;
                    margin-top: 10px;
                   .eye{
                       display: flex;
                       align-items: center;
                       font-size: 12px;
                       font-family: 'OPPOSans-M, OPPOSans';
                       font-weight: normal;
                       color: #001420;
                       margin-right: 30px;
                   }
                   .love{
                       display: flex;
                       align-items: center;
                       font-size: 12px;
                       font-family: 'OPPOSans-M, OPPOSans';
                       font-weight: normal;
                       color: #001420;
                   }
               }
               .label-list-box{
                      display: flex;
                      line-height: 30px;
                     .lael-item{
                        font-size: 12px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #bbb;
                        margin-right: 20px;
                     }
               }
               .ycs{
                     width: 100%;
                     display: flex;
                     margin-top: 10px;
                     .skybulue{
                       height: 16px;
                       font-size: 12px;
                       font-family: 'OPPOSans-M, OPPOSans';
                       font-weight: normal;
                       color: #008EFA;
                     }
                     .date{
                       font-size: 12px;
                       font-family: 'OPPOSans-M, OPPOSans';
                       font-weight: normal;
                       color: #888;
                       margin-left: 20px;
                     }
               }
               .works-count{
                    font-size: 12px;
                    margin-top: 10px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #bbb;
               }
               .footer-status{
                     display: flex;
                     margin-top: 70px;
                     .status-left{
                        font-size: 16px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #bbb;
                     }
                     .status-right{
                        font-size: 16px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #bbb;
                        margin-left: 30px;
                     }
               }
           }
           
       }
       .dl:hover{
            background: #F8FAFD;
            border-radius: 8px;
       }
       .create-button{
        width: 70%;
        height: 130px;
        background: #FAFAFA;
        border-radius: 4px;
        font-size: 14px;
        font-family: OPPOSans-M, OPPOSans;
        font-weight: normal;
        color: #bbb;
        text-align: center;
        line-height: 130px;
        margin: 40px 0;
    }
    #none{
         display: none;
    }
 }
}
