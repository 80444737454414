.fixed-label{
    width: 100%;
    display: flex;
    padding-left: 17%;
    align-items: center;
    height: 0.36rem;
    font-size: 0.12rem;
    font-family: 'AppleColorEmoji';
    color: #202020;
    background: #EFF8FE;
    display: none;
}