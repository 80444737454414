.PersonalInformation {
  width: 100%;
  height: 1000px;

  // background: red;
  .header-card {
    width: 100%;
    height: 288px;
    background: #f9f9f9;

  }

  .border-ruadus-box {
    width: 100%;
    height: 50px;
    border-radius: 60px 60px 0px 0px;
  }

  .max-box {
    width: 1240px;
    min-width: 798px;
    margin: 20px auto;
  }

  .header-card-content {
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 100%;
      display: flex;

      .avatar {
        width: 120px;
        height: 120px;
      }

      .right-content {
        margin-left: 20px;
        width: 100%;

        .nick-name,
        .address,
        .describe {
          width: 100%;
          margin: 15px 0;
          font-size: 14px;
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
          color: #001420;
        }

        .nick-name {
          font-size: 20px;
          font-family: "OPPOSans-M, OPPOSans";
          font-weight: normal;
          color: #001420;
          line-height: 26px;
          margin-bottom: 10px;
        }

        .address {
          font-size: 14px;
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
          color: #001420;
          margin-bottom: 10px;
          word-wrap: break-word;
        }

        .describe {
          width: 700px;
          font-size: 16px;
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
          color: #001420;
          line-height: 21px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /* 这里是超出几行省略 */
          overflow: hidden;
        }

        .describe-box {
          display: flex;
          width: 500px;
          justify-content: space-between;

          //   margin-left: -36px;
          .describe-box-one {
            font-size: 16px;
            font-family: "OPPOSans-M", "OPPOSans";
            font-weight: normal;
            color: #001420;

            span {
              font-size: 16px;
              font-family: OPPOSans-B, OPPOSans;
              font-weight: normal;
              color: #001420;
            }
          }
        }
      }
    }

    .right {
      width: 439px;
      height: 201px;
      background: #ffffff;
      box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.03);
      border-radius: 20px;
      border: 1px solid #ececec;

      .header {
        width: 91%;
        height: 60px;
        margin-left: 20px;
        line-height: 60px;
        border-bottom: 1px solid #eee;
        font-size: 24px;
        font-family: OPPOSans-M, OPPOSans;
        font-weight: normal;
        color: #001420;
      }

      .content {
        margin-left: 20px;

        .content-account {
          font-size: 16px;
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
          color: #001420;
          margin-top: 20px;
        }

        .content-price {
          font-size: 24px;
          font-family: OPPOSans-M, OPPOSans;
          font-weight: normal;
          color: #001420;
        }
      }
    }
  }

  .header-table-box {
    width: 1240px;
    margin: 0 auto;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;


    .header-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 400px;

      div {
        text-align: center;
        height: 46px;
        padding: 6px;
        font-size: 20px;
        font-family: OPPOSans-B, OPPOSans;
        font-weight: normal;
        color: #001420;
        margin-top: 10px;
        font-size: 14px;

        a {
          text-decoration: none;
          color: #001420;
        }
      }

      #active {
        border-bottom: 3px solid #202020;
      }
    }
  }

  .colections-List-box {
    width: 1240px;
    margin: 0 auto;

    .noStatusBack {
      position: relative;
      left: 50%;
      top: 300px;
    }

    .lable-form-select-box1 {
      line-height: 40px;
    }

    .colections-List {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
    }
  }

  .follow-list-box {
    width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .edit-user {
    font-size: 14px;
    color: #93989A;
    cursor: pointer;
  }

  .my-transaction {
    width: 100%;
    height: 888px;
    background: #fff;
    border-radius: 60px;
    padding-top: 40px;

    .my-transaction-content {
      width: 80%;
      height: 888px;
      margin: 0 auto;

      .my-title {
        width: 96px;
        height: 32px;
        font-size: 24px;
        font-family: OPPOSans-M, OPPOSans;
        font-weight: normal;
        color: #001420;
        line-height: 32px;
        margin-left: 30px;
      }

      .header-table-box {
        width: 100%;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #eee;

        .header-table {
          display: flex;
          align-items: center;

          div {
            width: 120px;
            text-align: center;
            height: 46px;
            padding: 6px;
            font-size: 20px;
            font-family: OPPOSans-B, OPPOSans;
            font-weight: normal;
            color: #001420;
            margin-top: 22px;
          }

          .active {
            border-bottom: 6px solid #001420;
          }
        }

        .total {
          width: 104px;
          height: 26px;
          font-size: 20px;
          font-family: OPPOSans-M, OPPOSans;
          font-weight: normal;
          color: #001420;
          line-height: 26px;
        }
      }

      .attribute {
        display: flex;
        margin-top: 30px;
        margin-bottom: 10px;
        margin-left: 20px;

        div {
          font-size: 16px;
          font-family: OPPOSans-M, OPPOSans;
          font-weight: normal;
          color: #001420;
        }

        .one {
          width: 20%;
        }

        .two {
          width: 40%;
        }

        .tree {
          width: 11%;
        }
      }

      .list {
        width: 100%;
        height: 220px;

        .list-card {
          .list-card-header {
            width: 98.75%;
            height: 48px;
            background: #f6f6f6;
            padding-left: 20px;
            display: flex;
            align-items: center;
            font-family: OPPOSans-R, OPPOSans;
            font-size: 14px;
            font-weight: normal;
            color: #001420;

            .list-card-header-first {
              width: 20.5%;
            }

            .list-card-header-last {
              width: 40%;
            }
          }

          .list-card-detail {
            height: 175px;
            padding-left: 20px;
            padding-top: 20px;
            display: flex;

            .avate-meassage {
              display: flex;
              width: 25%;

              .avata-name {
                margin-left: 20px;
                font-size: 14px;
                font-family: "OPPOSans-R, OPPOSans";
                font-weight: normal;
                color: #001420;
              }
            }

            .pron-message {
              display: flex;
              width: 1400px;

              div {
                font-size: 14px;
                font-family: OPPOSans-R, OPPOSans;
                font-weight: normal;
                color: #001420;
              }

              .one {
                width: 51%;
                font-size: 14px;
                font-family: "OPPOSans-R, OPPOSans";
                font-weight: normal;
                color: #001420;
              }

              .two {
                width: 14%;
                font-size: 14px;
                font-family: "OPPOSans-R, OPPOSans";
                font-weight: normal;
                color: #001420;
              }

              .tree {
                width: 13%;
                font-size: 14px;
                font-family: "OPPOSans-R, OPPOSans";
                font-weight: normal;
                color: #001420;
              }
            }
          }
        }
      }
    }
  }

  .collection-search,
  .folling-search {
    width: 1240px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .collection-search>.ant-select-selector {
    border-radius: 15px;
  }

  .folling-type {
    width: 140px;
    height: 36px;
    background: #d8d8d8;
    border-radius: 18px;
    opacity: 0.2;
    color: #001420;
    font-size: 14px;
    font-family: 'OPPOSans-M, OPPOSans';
    font-weight: normal;
  }

  .folling-type.active {
    background: rgba(0, 142, 250, 0.08);
    border: 1px solid #008efa;
    color: #008EFA;
    opacity: 1;
  }
}