@font-face {
    font-family: 'OPPOSans-B';
    src: url('./OPPOSans-B.ttf');   /* 注意这里一定要是英文字母，服务器不识别中文 */
  }
  @font-face {
    font-family: 'OPPOSans-H';
    src: url('./OPPOSans-H.ttf');   /* 注意这里一定要是英文字母，服务器不识别中文 */
  }
  @font-face {
    font-family: 'OPPOSans-L';
    src: url('./OPPOSans-L.ttf');   /* 注意这里一定要是英文字母，服务器不识别中文 */
  }
  @font-face {
    font-family: 'OPPOSans-M';
    src: url('./OPPOSans-M.ttf');   /* 注意这里一定要是英文字母，服务器不识别中文 */
  }
  @font-face {
    font-family: 'OPPOSans-R';
    src: url('./OPPOSans-R.ttf');   /* 注意这里一定要是英文字母，服务器不识别中文 */
  }