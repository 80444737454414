.nftDetail{
     width: 100%;
     margin-top: 50px;
     .nftDetail-content{
           width: 1240px;
           margin: 0 auto;
           padding-bottom: 50px;
           .avatar-header{
                 display: flex;
                 width: 100%;
                 justify-content: space-around;
                 .left{
                    width: 604px;
                    height: 813px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.08);
                    border-radius: 24px;
                    position: relative;
                    overflow: hidden;
                    .avatar-detials{
                        width: 604px;
                        height: 604px;
                     }
                     .works-detial-card{
                            width: 100%;
                            padding-top: 20px;
                           .detial-card-price{     
                                 display: flex;
                                 justify-content: space-between;
                                 align-items: center;
                                 width: 80%;
                                 margin: 0 auto;
                                .price-label{
                                    font-size: 24px;
                                    font-family: 'OPPOSans-M, OPPOSans';
                                    font-weight: normal;
                                    color: #001420;
                                }
                                .price{
                                    font-size: 32px;
                                    font-family: OPPOSans-B, OPPOSans;
                                    font-weight: normal;
                                    color: #001420;
                                    display: flex;
                                    align-items: center;
                                    .price-symbel{
                                        font-size: 24px;
                                        font-family: OPPOSans-B, OPPOSans;
                                        font-weight: normal;
                                        color: #001420;
                                    }
                               }
                           }
                           .buy-Butone{
                            position: absolute;
                            width: 80%;
                            height: 60px;
                            border-radius: 8px;
                            font-size: 22px;
                            font-family: "OPPOSans-B, OPPOSans";
                            font-weight: "normal";
                            color: "#F9F9F9";
                            left: 55px;
                            bottom:50px;
                      }
                     }
                 }
                 .right{       
                         width: 550px; 
                         margin-left: 50px;
                         .work-detial-title-box{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 50px;
                            .right-label-title{
                                font-size: 36px;
                                font-family: OPPOSans-M, OPPOSans;
                                font-weight: normal;
                                color: #001420;
                           }
                           .house{
                                display: flex;
                                align-items: center;
                                font-size: 16px;
                                .anticon svg{
                                      width: 30px;
                                      height: 20px;
                               }
                           }
                         }  
                       
                        .music-label{
                              width: 80px;
                              height: 24px;
                              background: #EBFBFC;
                              border-radius: 4px;
                              text-align: center;
                              border: 1px solid #75E6EC;
                              font-size: 14px;
                              font-family: OPPOSans-R, OPPOSans;
                              font-weight: normal;
                              color: #0FA8BE;
                              margin-top: 40px;
                        }
                        .serives-box-lable{
                            font-size: 24px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #bbb;
                            margin-top: 100px;
                        }
                        .serives-content{
                            font-size: 20px;
                            font-family: 'OPPOSans-M, OPPOSans';
                            font-weight: normal;
                            color: #001420;
                            position: relative;
                            top:-50px;
                        }
                        .nftDetail-right-card{
                              height: 262px;
                              border-radius: 8px;     
                              .nftDetail-card-one{
                               display: flex;
                               align-items: center;
                               margin-bottom: 30px;
                               justify-content: space-between;
                                  .jbxx{
                                          height: 22px;
                                          font-size: 24px;
                                          font-family: OPPOSans-M, OPPOSans;
                                          font-weight: normal;
                                          color: #666;
                                          line-height: 22px;
                                    }
                                    .nftDetail-discrib{
                                          width: 163px;
                                          display: flex;
                                           .icon-avatar{
                                                width: 20px;
                                                height: 20px;
                                           }
                                           span{
                                                width: 150px;
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: OPPOSans-M, OPPOSans;
                                                font-weight: normal;
                                                color: #666;
                                                line-height: 21px;
                                                margin-left: 10px;
                                           }
                                    }  
                              }
                              .nftDetail-address{
                                    font-size: 18px;
                                    font-family: 'OPPOSans-R, OPPOSans';
                                    font-weight: normal;
                                    color: #000000;
                                    margin-bottom: 10px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .one{
                                        font-size: 20px;
                                        font-family: 'OPPOSans-M, OPPOSans';
                                        font-weight: normal;
                                        color: #001420;
                                    }
                                    .two{
                                        font-size: 20px;
                                        font-family: OPPOSans-M, OPPOSans;
                                        font-weight: normal;
                                        color: #001420;
                                    }
                              }
                              .smart-avatar{
                                     width: 130px;
                                     margin-top: 30px;
                                     .czz{
                                          font-size: 14px;
                                          font-family: OPPOSans-R, OPPOSans;
                                          font-weight: normal;
                                          color: #666;
                                     }
                                     .smart-avatar-wenzi{
                                          display: flex;
                                          align-items: center;
                                          width: 200px;
                                            margin-top: 20px;
                                           .czz-atartar{
                                                width: 48px;
                                                height: 47px;
                                              
                                           }
                                           .mbs{
                                            font-size: 16px;
                                            font-family: "OPPOSans-M, OPPOSans";
                                            font-weight: normal;
                                            color: #008EFA;
                                            margin-left: 20px;
                                       }
                                     }         
                              }
                        }
                        
                 }
           }
           .zpxljs{
                  width: 100%;
                  padding-bottom: 50px;
                  background: #FBFBFB;
                  padding-top: 10px;
                  padding-left: 40px;
                  padding-right: 100px;
                  margin-bottom: 30px;
                  top:80px;
                  position: relative;
                  .zpxljs-title{
                        font-size: 32px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                        padding-bottom: 30px;
                  }
                  .zpxljs-maioshu{
                        font-size: 20px;
                        font-family: OPPOSans-R, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                  }
                  .label-icon{
                        width: 8px;
                        height: 60px;
                        background: #202020;
                        opacity: 0.95;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                  }
           }
           .more-works{
                  height: 42px;
                  font-size: 32px;
                  font-family: 'OPPOSans-M, OPPOSans';
                  font-weight: normal;
                  color: #001420;
                  line-height: 42px;
                  margin-top: 100px;
           }
           .content {
            margin-top: 30px;
            height: 500px;
            overflow-y: scroll;
            .rc-virtual-list-holder-inner{
                //   flex-direction: row!important;
                flex-wrap: wrap;
            }
            .card-my {
                width: 286px;
                height: 417px;
                overflow: hidden;
                margin: 30px 10px;
                display: block;
                margin-bottom: 30px;
                box-shadow: 0px 2px 20px 0px rgba(0, 20, 32, 0.08);
                border-radius: 15px 15px 15px 16px;
                position: relative;
                transition: 0.5s;
                cursor: pointer;
                .card-my-avatar {
                    width: 286px;
                    height: 286px;
                }
                .card-content-box {
                    height: 137px;
                    padding: 20px;
                    width: 286px;
                    .title {
                        width: 116px;
                        height: 32px;
                        font-size: 16px;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #000000;
                        line-height: 32px;
                        margin-top: 0px;
                    }
    
                    .miaoshu {
                        font-size: 12px;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #001420;
                    }
    
                    .card-content {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 15px;
    
                        .price {
                            height: 28px;
                            font-size: 16px;
                            font-family: 'OPPOSans-B, OPPOSans';
                            font-weight: 600;
                            color: #000000;
                            line-height: 28px;
                            display: flex;
    
                            .price-symbol {
                                font-size: 14px;
                            }
                        }
    
                        .house {
                            font-size: 14px;
                            font-family: 'OPPOSans-M, OPPOSans';
                            font-weight: normal;
                            color: #000000;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
    
                .button {
                    position: absolute;
                    width: 100px;
                    height: 40px;
                    background: #008EFA;
                    border-radius: 8px;
                    left: 95px;
                    bottom: 155px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 14px;
                    font-family: 'OPPOSans-M, OPPOSans';
                    font-weight: normal;
                    color: #FFFFFF;
                    display: none;
                }
    
                .label {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 60px;
                    height: 28px;
                    font-size: 12px;
                    background: #070707;
                    border-radius: 22px;
                    opacity: 0.6;
                    color: #fff;
                    text-align: center;
                    line-height: 28px;
                    display: none;
                }
    
                .avatar-mask {
                    position: absolute;
                    z-index: 5;
                    top: 0px;
                    right: 0px;
                    width: 286px;
                    height: 417px;
                    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.17) 100%);
                    border-radius: 12px 12px 0px 0px;
                    opacity: 0.3;
                    display: none;
                }
            }
    
            .card-my:hover .card-content-box {
                background: rgba(0, 0, 0, 0.16);
    
            }
    
            .card-my:hover {
                transform:translateY(-10px);
            }
    
            .card-my:hover .avatar-mask {
                display: block;
            }
    
            .card-my:hover .label {
                display: block;
            }
    
            .card-my:hover .button {
                display: block;
            }
        }
     }
}