.EditSeries {
    position: relative;
    left: 0px;
    top: -130px;
    background: #fff;
    width: 90%;
    height:1300px;
    .logo-avatar {
        width: 200px;
    }
    .CreateSeries-title {
        font-size: 24px;
        font-family: 'OPPOSans-M, OPPOSans';
        font-weight: normal;
        color: #001420;
        margin: 30px 0;
        height: 100px;
        line-height: 100px;
    }

    .EditSeries-form {
        position: absolute;
        top:110px;
        left: 75px;
        .ant-form-item {
            margin-bottom: 50px;
            margin-left: -250px;
        }

        .submit {
            width: 250px;
            height: 50px;
            background: #008EFA;
            border-radius: 8px;
        }

        .ant-col-8 {
            margin-right: 40px;
            width: 80px;
            font-size: 30px;
            font-weight: normal;
            color: #001420;
        }

        .ant-form-item-label>label {
            width: 100px !important;
            height: 26px;
            font-size: 20px;
            font-family: 'OPPOSans-M';
            font-weight: normal;
            color: #001420;
            line-height: 26px;
        }

        #basic_username {
            width: 500px;
            height: 60px;
            background: #F0F1F4;
            border-radius: 8px;
            padding-left: 20px;
        }

        .ant-form-horizontal .ant-form-item-control {
            margin-left: 50px;
        }

    }

    .createSeries-logo-box {
        border-radius: 50%;
        text-align: center;
        width: 120px;
        height: 120px;
        line-height: 120px;
        background: #F6F6F6;
        border: 1px solid #EBEBEB;
        color: #008EFA;
        font-size: 24px;
    }

    .createSeries-face-box {
        width: 281px;
        height: 187px;
        background: #F6F6F6;
        border-radius: 4px;
        border: 1px solid #EBEBEB;
        color: #008EFA;
        font-size: 24px;
        line-height: 187px;
        text-align: center;
    }

    .createSeries-home-box {
        width: 700px;
        height: 111px;
        background: #F6F6F6;
        border-radius: 4px;
        border: 1px solid #EBEBEB;
        color: #008EFA;
        font-size: 24px;
        line-height: 111px;
        text-align: center;
    }

}