.homes {
    width: 100%;
    .homes-content {
        width: 1240px;
        margin: auto;
    }
    // width: 100%;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
   
    
  
    // .homes-content{
    //     width: 67%;
    //     margin: 0 auto;
    //     // height: 12.5rem;
    //     display: flex;
    //     flex-direction: column;
    //     // background: url("https://demos.creative-tim.com/now-ui-kit-react/static/media/bg7.6249276d.jpg") no-repeat;
    //     background-position: center center;
    //     background-size: 26rem;
    //     padding: 0 5%;
       
        
        
    // }
}