.wallet {
  width: 19.2rem;
  height: 12rem;
  background: #F9F9F9;

  .ant-modal-body {
    height: 1.4rem;

  }

  .ant-modal-content {
    border-radius: 0.2rem !important;
    top: 2.5rem;
  }

  // .ant-input-affix-wrapper>input.ant-input {
  // background: #F0F1F4;
  // }
  .ant-input-suffix {
    font-size: 0.16rem;
  }

  .space {
    height: 0.2rem;
  }

  .father-content {
    margin: 0 auto;
    width: 76%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .left-card {
      width: 3rem;
      height: 10.86rem;
      background: #fff;

      .setUp-button {
        margin-top: 0.6rem;
        margin-bottom: 0.5rem;
        margin-left: 0.65rem;
        height: 0.4rem;
        font-size: 0.3rem;
        font-family: OPPOSans-M, OPPOSans;
        font-weight: normal;
        color: #001420;
        line-height: 0.4rem;
      }

      .ul {
        margin-left: 0.35rem;
        font-weight: normal;

        li {
          width: 1.65rem;
          height: 0.6rem;
          line-height: 0.6rem;
          text-align: center;
          font-size: 0.24rem;
          color: #bbb;

          a {
            text-decoration: none;
            color: #bbb;
          }
        }

        .active {
          background: #F3F7FB;
          border-radius: 0.06rem;
          color: #001420;

          a {
            text-decoration: none;
            color: #001420;
          }
        }
      }
    }

    .right-card {
      width: 11.55rem;
      height: 10.86rem;
      background: #fff;

      .setUp-form {
        width: 10rem;
        height: 5rem;
        margin-top: 0.6rem;
        display: flex;
        justify-content: space-around;
        position: relative;

        .title-form {
          position: absolute;
          left: 1rem;
          top: 0.5rem;
          width: 0.96rem;
          height: 0.32rem;
          font-size: 0.24rem;
          font-family: OPPOSans-M, OPPOSans;
          font-weight: normal;
          color: #001420;
          line-height: 0.32rem;
        }

        .integral {
          width: 4.42rem;
          height: 2.8rem;
          background: url(../../assets/img/wallet.png) no-repeat;
          background-size: 100% 100%;
          border-radius: 0.14rem;
          position: absolute;
          top: 1rem;
          left: 1rem;
          color: #fff;
          padding: 0.4rem;

          .walletBack {}

          .integral-title {
            height: 0.8rem;

            .integral-title-one {
              font-size: 0.16rem;
              font-family: 'OPPOSans-M, OPPOSans';
              font-weight: normal;
              color: #80C6FC;
              display: flex;
              justify-content: space-between;

              .pay-admin-label {

                //  width: 1rem;
                a {
                  display: block;
                  flex-wrap: nowrap;
                  text-decoration: none;
                  color: #000;
                }
              }
            }

            .balance {
              font-size: 0.3rem;
              font-family: OPPOSans-B, OPPOSans;
              font-weight: normal;
              color: #FFFFFF;
              margin-bottom: 1rem;
            }
          }

          .pay-admin-label {
            font-size: 0.14rem;
            font-family: OPPOSans-M, OPPOSans;
            font-weight: normal;
            color: #001420;
            position: relative;
            left: 3rem;
            margin-top: -0.8rem;
          }

          .footer {
            display: flex;
            justify-content: space-between;
            margin-top: 1.2rem;

            .walletID {
              div {
                font-size: 0.16rem;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #80C6FC;
              }

              span {
                font-size: 0.16rem;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #FFFFFF;
              }
            }

            .right {
              display: flex;
              justify-content: space-between;
              margin-top: 0.2rem;

              .button-one {
                padding: 0.07rem;
                height: 0.32rem;
                text-align: center;
                background: #fff;
                font-size: 0.14rem;
                font-family: 'OPPOSans-M, OPPOSans';
                font-weight: normal;
                border-radius: 0.09rem;
                display: flex;
                align-items: center;
                margin-right: 0.2rem;

                a {
                  text-decoration: none;
                  color: #001420;
                  font-size: 0.14rem;
                }
              }

              .button-two {
                padding: 0.07rem;
                height: 0.32rem;
                text-align: center;
                background: #fff;
                font-size: 0.14rem;
                font-family: 'OPPOSans-M, OPPOSans';
                font-weight: normal;
                border-radius: 0.09rem;
                display: flex;
                align-items: center;

                a {
                  text-decoration: none;
                  color: #001420;
                }
              }
            }
          }
        }

        .recharge-points {
          width: 100%;
          position: absolute;
          top: 1.2rem;
          left: 1rem;

          .points-one {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;

            .points-label {
              font-size: 0.2rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
              margin-right: 0.58rem;
            }

            span {
              font-size: 0.2rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
            }
          }

          .points-two {
            display: flex;

            .points-label {
              font-size: 0.2rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
              margin-right: 0.2rem;
            }

            .two-box {
              width: 89%;
              display: flex;
              flex-wrap: wrap;

              li {
                width: 2.69rem;
                height: 1.4rem;
                background: #F5F6F6;
                border-radius: 0.08rem;
                margin-right: 0.16rem;
                margin-bottom: 0.3rem;
                text-align: center;

                .two-title {
                  font-size: 0.3rem;
                  font-family: OPPOSans-M, OPPOSans;
                  font-weight: normal;
                  color: #001420;
                  margin-top: 0.3rem;
                }

                .two-describe {
                  font-size: 0.2rem;
                  font-family: 'OPPOSans-M, OPPOSans';
                  font-weight: normal;
                  color: #788A96;
                }

                .custom {
                  width: 1.5rem;
                  height: 0.5rem;
                  border-top: none;
                  border-left: none;
                  border-right: none;
                  border-bottom: 0.01rem solid #bbb;
                }

                .Recharge {
                  font-size: 0.2rem;
                  font-family: 'OPPOSans-M, OPPOSans';
                  font-weight: normal;
                  color: #001420;
                  margin-top: 0.2rem;
                }

                .money-label-box {
                  display: flex;
                  align-items: center;
                  margin-left: 0.5rem;

                  .money_label {
                    font-size: 0.2rem;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                  }
                }
              }

              li:nth-child(4) {
                .custom {
                  font-size: 0.26rem;
                  background: #E6F4FF;
                }

                .custom:focus {

                  outline: none;
                }
              }

              .active {
                width: 2.69rem;
                height: 1.4rem;
                background: rgba(0, 142, 250, 0.1);
                border-radius: 0.08rem;
                border: 0.02rem solid #008EFA;
              }
            }

          }

          .points-three {
            display: flex;
            margin-bottom: 0.3rem;

            .points-label {
              font-size: 0.2rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
              margin-right: 0.4rem;
            }

            .pay {
              width: 0.93rem;
              height: 0.36rem;
              margin-left: 0.1rem;
            }
          }

          .points-four {
            display: flex;
            align-items: center;
            margin-bottom: 0.3rem;

            .points-label {
              font-size: 0.2rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
              margin-right: 0.4rem;
            }

            span {
              font-size: 0.4rem;
              font-family: OPPOSans-B, OPPOSans;
              font-weight: normal;
              color: #008EFA;
            }

            .yuan {
              font-size: 0.24rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
            }
          }

          p {
            margin-top: 0.15rem;
            font-size: 0.16rem;

            .one {
              color: #384851;
            }

            .two {
              color: #008EFA;
            }
          }
        }

        .bill-details {

          position: absolute;
          top: 1.1rem;
          left: 1rem;
          width: 100%;
          height: 9rem;
          overflow-y: scroll;

          .bill-select {
            display: flex;

            .bill-select-left {
              height: 1rem;
              position: relative;

              .label {
                font-size: 0.14rem;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #001420;
                padding-bottom: 0.1rem;
              }

              .ange-icker {
                position: absolute;
                top: 1.3rem;
                width: 4.2rem;
                margin-top: -1rem;
              }
            }

            .bill-select-right {
              margin-left: 4rem;
              position: relative;

              .label {
                font-size: 0.14rem;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #001420;
                padding-bottom: 0.1rem;
              }

              .Select {
                position: absolute;
                width: 2.5rem;
              }
            }
          }

          .bill-list-box {
            .bill-list-title {
              display: flex;
              width: 100%;
              font-size: 0.14rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
              border-bottom: 0.02rem solid #eee;

              div {
                width: 20%;
                height: 0.5rem;
                line-height: 0.5rem;
                font-size: 0.14rem;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #001420;
              }

              .one {
                padding-left: 0.2rem;
              }
            }

            .bill-list-card {
              width: 100%;
              margin-left: -0.35rem;

              .noStatusBack {
                position: relative;
                left: 0;
                top: 3rem;
              }

              .li {
                display: flex;
                width: 104%;
                align-items: center;
                height: 0.8rem;
                padding-left: 0.55rem;

                .list-card-one {
                  display: flex;
                  width: 20%;
                  font-size: 0.14rem;

                  .type-icon {
                    width: 0.36rem;
                    height: 0.36rem;
                    background: #90C8FF;
                    border-radius: 0.04rem;
                  }

                  .card-one-box {
                    margin-left: 0.1rem;

                    .card-one-title {
                      font-size: 0.14rem;
                      font-family: OPPOSans-M, OPPOSans;
                      font-weight: normal;
                      color: #001420;
                    }

                    .card-one-describe {
                      font-size: 0.12rem;
                      font-family: OPPOSans-R, OPPOSans;
                      font-weight: normal;
                      color: #001420;
                    }
                  }
                }

                .list-card-two {
                  font-size: 0.14rem;
                  font-family: OPPOSans-R, OPPOSans;
                  font-weight: normal;
                  color: #001420;
                }
              }

              li:hover {

                background: #F8F9F9;
                //   margin-right: 1rem;
              }
            }
          }
        }

        .pay-admin {
          position: absolute;
          width: 100%;
          top: 1.2rem;
          left: 1rem;

          .admin-one {
            .admin-input-box {
              .admin-input-label {
                font-size: 0.2rem;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                height: 0.5rem;
                color: #001420;
              }

              .admin-password {
                width: 4rem;
                height: 0.6rem;
                //  background: #F0F1F4;
                border-radius: 0.08rem;
                padding-left: 0.2rem;
              }
            }

            .button-ok {
              width: 1.2rem;
              height: 0.5rem;
              border-radius: 0.08rem;
              font-size: 0.2rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #FFFFFF;
              margin-top: 0.5rem;
            }
          }

          .status {
            display: flex;
            align-items: center;
            width: 100%;

            .status-one {
              width: 15%;
              font-size: 0.2rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
            }

            .status-two {
              width: 35%;
              font-size: 0.18rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
            }

            .status-three {
              font-size: 0.18rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #008EFA;
            }
          }

          .test-box {
            width: 100%;
            height: 5rem;
            position: absolute;
            background: #fff;
            top: -1.1rem;

            .test-back {
              display: flex;
              align-items: center;
              font-size: 0.16rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
              margin-bottom: 0.5rem;
              transform: scale(1.5);
              transform-origin: 0% 0%;
            }

            .test-title {
              font-size: 0.24rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
              margin-bottom: 0.2rem;
            }

            p {
              font-size: 0.16rem;
              font-family: OPPOSans-R, OPPOSans;
              font-weight: normal;
              color: #aaa;
              margin-bottom: 0.3rem;
            }

            .test-describe {
              font-size: 0.14rem;
              font-family: OPPOSans-M, OPPOSans;
              font-weight: normal;
              color: #001420;
              margin-bottom: 0.1rem;
            }

            .message-test-box {
              width: 3.6rem;
              height: 0.55rem;
              border-radius: 0.08rem;
              margin-bottom: 0.6rem;
              position: relative;

              .message-test {
                width: 3.6rem;
                height: 0.55rem;
                background: #F0F1F4;
                border-radius: 0.08rem;
              }

              .link-button {
                position: absolute;
                right: 0rem;
                top: 0.1rem;
              }
            }

            .button-ok {
              width: 1.2rem;
              height: 0.5rem;
              border-radius: 0.08rem;

            }
          }
        }
      }
    }
  }
}

// .ant-modal-footer {
//   text-align: center !important;
//   border-top: none !important;
//   //    margin-bottom: 2rem;
//   height: 1rem;

//   .ant-btn-primary {
//     width: 1.6rem;
//     height: 0.48rem;
//     border-radius: 0.08rem !important;
//     font-size: 0.16rem;
//     font-family: OPPOSans-M, OPPOSans;
//     font-weight: normal;
//   }

//   .ant-btn-default {
//     width: 1.6rem;
//     height: 0.48rem;
//     border-radius: 0.08rem !important;
//     font-size: 0.16rem;
//     font-family: OPPOSans-M, OPPOSans;
//     font-weight: normal;
//     background: #EEEEEE;
//   }
// }