.category-browse {
  .category-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 84px;
  }

  .category-card {
    width: 604px;
    height: 400px;
    position: relative;
    margin-top: 41px;
    cursor: pointer;

    .category-img {
      // background: url(../../../../assets/img/head-portrait.jpg) no-repeat;
      background-size: cover;
      // width: 604px;
      height: 400px;

      .mark-des {
        width: 100%;
        height: 60px;
        background: #008EFA;
        font-size: 18px;
        color: #fff;
        text-align: center;
        line-height: 60px;
        position: absolute;
        bottom: 0;
        border-radius: 0 0 16px 16px;
      }
    }
  }

  .category-card:hover {
    transform: translateY(-10px);
  }

  .title {
    color: #001420;
    font-size: 40px;
    margin-top: 60px;
  }
}