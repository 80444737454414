.dialog{
     .forget_password{
         
     }
     #login-form-forgot{
        text-decoration: none;
        color: red;
        margin-left: 3rem;
        position: relative;
        left: 2.5rem;
    }
}
 