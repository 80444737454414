.headerTop {
    width: 100%;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    padding: 70px 0;

    .homes-header-box {
        display: flex;
        margin: 0 auto;
        width: 1240px;
        justify-content: space-between;

        .bannner {
            width: 382px;
            margin-left: 7%;

            // .box-shodow {
            //     position: absolute;
            //     width: 3.82rem;
            //     height: 3.82rem;
            //     opacity: 0.33;
            //     filter: blur(0.56rem);
            //     background: #000;
            //     bottom: -0.3rem;
            //     left: -0.5rem;
            //     z-index: -1;
            // }

            .ant-carousel .slick-dots-bottom {
                bottom: 61px;
            }

            .ant-carousel {
                z-index: 5;

                .slick-dots {}

                .slick-dots li {
                    width: 50px;
                    height: 2px;
                    background: #fff;
                    margin: 0;
                }

                .slick-active {
                    width: 0.5rem;
                    height: 0.03rem;
                }

                .slick-active button {
                    background: #000;
                }

                .bannner-avartar {
                    width: 382px;
                    height: 382px;
                }
            }

            .bannner-content-box {
                .left-title {
                    font-size: 18px;
                    color: #202020;
                    margin-top: 32px;
                    display: flex;
                    align-items: center;
                    .bannner-label-avatar {
                        width: 100px;
                        height: 18px;
                    }
                }

                .left-describle {
                    font-size: 14px;
                    color: #001420;
                }
            }
        }

        .banner-item-box {
            position: relative;
            .border-shadow {
                
            }
        }

        .homes-header-right {

            .common {
                font-size: 54px;
                color: #202020;
                font-weight: 600;
                line-height: 64px;
                
            }

            .right-describe {
                font-size: 16px;
                color: #202020;
                margin-top: 14px;
            }

            .right-button-box {
                display: flex;
                margin-top: 50px;
                button {
                    width: 160px;
                    height: 60px;
                    border-radius: 8px;
                    font-size: 22px;
                    font-weight: normal;
                    color: #FFFFFF;   
                }

                .button-left {
                    color: #FFFFFF;
                }

                .button-right {
                    color: #008EFA;
                    margin-left: 24px;

                }
            }
        }
        // .bannner-avartar {
        //     span {
        //         display: block;
        //     }
        // }
    }
}