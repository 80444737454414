.setUp {
    width: 19.2rem;
    height: 15.3rem;
    background: #F9F9F9;

    .space {
        height: 0.2rem;
    }

    .father-content {
        margin: 0 auto;
        width: 76%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .left-card {
            width: 3rem;
            height: 14.86rem;
            background: #fff;

            .setUp-button {
                margin-top: 0.6rem;
                margin-bottom: 0.5rem;
                margin-left: 0.65rem;
                width: 0.6rem;
                height: 0.4rem;
                font-size: 0.3rem;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #001420;
                line-height: 0.4rem;
            }

             .menus{
                margin-left: 0.35rem;
                font-weight: normal;
                div {
                    width: 1.65rem;
                    height: 0.6rem;
                    line-height: 0.6rem;
                    text-align: center;
                    font-size: 0.24rem;
                    color: #bbb;
                }

                .active {
                    background: #F3F7FB;
                    border-radius: 0.06rem;
                    color: #001420;
                }
            }
        }

        .right-card {
            width: 11.55rem;
            height: 14.86rem;
            background: #fff;

            .setUp-form {
                width: 8rem;
                height: 5rem;
                margin-top: 0.6rem;
                display: flex;
                justify-content: space-around;
                position: relative;
                .title-form {
                    position: absolute;
                    left: 1rem;
                    top: 0.5rem;
                    width: 0.96rem;
                    height: 0.32rem;
                    font-size: 0.24rem;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                    line-height: 0.32rem;
                }
                
                .message-card{
                     position: absolute;
                     width: 10rem;
                     left: 0.7rem;
                     top: 1.5rem;
                     .card-children{
                        padding-bottom: 0.5rem;
                        border-bottom: 0.01rem solid #eee;
                        .title-list{
                            display: flex;
                            height: 0.3rem;
                            align-items: center;
                             .icon{
                                width: 0.32rem;
                                height: 0.32rem;
                                background: #FDA200;
                                border-radius: 50%;
                                opacity: 0.2;
                                margin-right: 0.15rem;
                             } 
                             .title{
                                font-weight: 600;
                                font-size: 0.2rem;
                                font-family: 'OPPOSans-R';
                                font-weight: normal;
                                color: #001420;
                             }
                        }
                        .describe{
                            margin-left:0.5rem;
                            margin-top: 0.1rem;
                            color: #bbb;
                            font-size: 0.16rem;
                        }
                     }
                }
               
            }
        }
    }
}