.worksSeries {
    width: 100%;
    padding-bottom: 1rem;

    .worksSeries-banner {
        width: 100%;
        height: 3.1rem;
        position: relative;
        margin-bottom: 0.8rem;

        .backgroud-Avatar {
            width: 100%;
            height: 3.1rem;
        }

        .worksSeries-Avatar {
            width: 2.12rem;
            height: 2.12rem;
            position: absolute;
            left: 17.8%;
            bottom: -0.4rem;
            border-radius: 50%;
            border: 0.06rem solid #fff;
        }
    }

    .worksSeries-title-box {
        width: 67%;
        margin: 0 auto;
        .worksSeries-title-content {
            .dimentsion-box {
                display: flex;
                justify-content: space-between;

                .dimentsion-left {
                    .dimentsion {
                        text-align: center;
                        font-size: 0.3rem;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #001420;
                    }
                    .zuozhe {
                        font-size: 0.16rem;
                        margin-top: 0.1rem;
                        text-align: center;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #001420;
                    }
                }

                .dimentsion-right {
                    font-size: 0.2rem;
                    font-family: 'OPPOSans-M, OPPOSans';
                    font-weight: normal;
                    color: #000000;
                    display: flex;
                    align-items: center;
                }
            }

            .data-total {
                width: 4.82rem;
                height: 0.96rem;
                background: #FFFFFF;
                border-radius: 0.08rem;
                border: 0.01rem solid #CCCCCC;
                display: flex;
                align-items: center;
                margin-top: 0.5rem;
                        .data-total-title {
                            font-size: 0.16rem;
                            font-family: 'OPPOSans-M, OPPOSans';
                            font-weight: normal;
                            color: #001420;
                            text-align: center;
                        }

                        .data-total-content {
                            font-size: 0.2rem;
                            font-family: 'OPPOSans-M, OPPOSans';
                            font-weight: normal;
                            color: #001420;
                            text-align: center;
                        }
                    .data-total-left {
                        width: 33.3%;
                    
                    }

                    .data-total-center {
                        width: 33.3%;
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                    }

                    .data-total-right {
                        width: 33.3%;
                    
                    }
            }

            .dimentsion-describe {
                margin-top: 0.3rem;      
                font-size: 0.2rem;
                font-family: 'OPPOSans-R, OPPOSans';
                font-weight: normal;
                color: #001420;
                margin-bottom: 0.4rem;
            }

            .avatar-logo {
                width: 0.8rem;
                margin: 0 auto;
                text-align: center;
                margin-bottom: 0.2rem;

                .avatar-logo-one {
                    width: 0.6rem;
                    height: 0.6rem;
                }

                .header-nickname {
                    font-size: 0.2rem;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                }
            }

        }


    }

    .worksSeries-card {
        width: 67%;
        margin: 0 auto;

        .selects {}

        .content {
            margin-top: 0.3rem;
            display: flex;
            flex-wrap: wrap;
            .noStatusBack{
                position: relative;
                left: 50%;
                top:1rem;
            }
            .card-my {
                width: 2.60rem;
                height: 3.97rem;
                overflow: hidden;
                margin-bottom: 0.3rem;
                box-shadow: 0rem 0.02rem 0.2rem 0rem rgba(0, 20, 32, 0.08);
                border-radius: 0.15rem 0.15rem 0.15rem 0.16rem;
                position: relative;
                margin-right: 0.3rem;
                .card-my-avatar {
                    width: 2.60rem;
                    height: 2.60rem;
                }

                .card-content-box {
                    height: 1.37rem;
                    padding: 0.2rem;
                    width: 100%;

                    .title {
                        width: 116px;
                        height: 32px;
                        font-size: 0.16rem;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #000000;
                        line-height: 32px;
                        margin-top: 0rem;
                    }

                    .miaoshu {
                        font-size: 0.12rem;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #001420;
                    }

                    .card-content {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0.15rem;

                        .price {
                            height: 0.28rem;
                            font-size: 0.16rem;
                            font-family: 'OPPOSans-B, OPPOSans';
                            font-weight: 600;
                            color: #000000;
                            line-height: 0.28rem;
                            display: flex;

                            .price-symbol {
                                font-size: 0.14rem;
                            }
                        }

                        .house {
                            font-size: 0.14rem;
                            font-family: 'OPPOSans-M, OPPOSans';
                            font-weight: normal;
                            color: #000000;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .button {
                    position: absolute;
                    width: 1rem;
                    height: 0.4rem;
                    background: #008EFA;
                    border-radius: 0.08rem;
                    left: 0.8rem;
                    bottom: 1.8rem;
                    text-align: center;
                    line-height: 0.4rem;
                    font-size: 0.14rem;
                    font-family: 'OPPOSans-M, OPPOSans';
                    font-weight: normal;
                    color: #FFFFFF;
                    display: none;
                }

                .label {
                    position: absolute;
                    top: 0.2rem;
                    right: 0.2rem;
                    width: 0.6rem;
                    height: 0.28rem;
                    font-size: 0.12rem;
                    background: #070707;
                    border-radius: 0.22rem;
                    opacity: 0.6;
                    color: #fff;
                    text-align: center;
                    line-height: 0.28rem;
                    display: none;
                }

                .avatar-mask {
                    position: absolute;
                    z-index: 5;
                    top: 0rem;
                    right: 0rem;
                    width: 2.60rem;
                    height: 2.60rem;
                    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.17) 100%);
                    border-radius: 0.12rem 0.12rem 0rem 0rem;
                    opacity: 0.3;
                    display: none;
                }
            }

            .card-my:hover .card-content-box {
                background: rgba(0, 0, 0, 0.16);

            }

            .card-my:hover {
                bottom: 0.1rem;
            }

            .card-my:hover .avatar-mask {
                display: block;
            }

            .card-my:hover .label {
                display: block;
            }

            .card-my:hover .button {
                display: block;
            }
        }
    }
}