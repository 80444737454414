.login {
    width: 100%;
    height: 100%;
    padding-left: 10%;
    background: url(https://img.js.design/assets/img/6341126d2c8c820e3e1394be.png);
    .header {
        img {
           width: 151px; 
           height: 49px;
        }
    }

    .ant-input {
        border-radius: 8px;
        width: 100%;
        height: 60px;
        border: 1px solid rgba(37, 48, 67, 0.4);
    }

    .phone {
        font-size: 18px;
        padding-left: 0.8rem;
    }

    .login-form-button {
        width: 100%;
        height: 60px;
        border-radius: 8px;
        background: #008EFA;
        font-size: 20px;
        color: #fff;

    }

    .loginBtn {
        margin-top: 82px;
    }

    .otherBtn {
        margin: 44px 0 13px 0;
    }




    #basic_password {
        font-size: 18px;
        height: 100%;
        width: 100%;

        // width: 129%;
        // padding-left: 0.12rem;
        // margin-top: -0.01rem;
        // height: 0.46rem;
    }

    #basic_confirm {
        font-size: 0.18rem;
        width: 129%;
        padding-left: 0.12rem;
        margin-top: -0.01rem;
        height: 0.46rem;
    }

    #basic_username {
        font-size: 0.18rem;
        padding-left: 0.20rem;
    }

    #basic_name {
        font-size: 18px;
        padding-left: 20px;
    }

    #basic_code {
        font-size: 0.18rem;
        padding-left: 0.20rem;

    }

    .ant-form-item-explain-error {
        font-size: 14px;
        color: #FF5733;
        font-weight: 500;
    }

    .ant-input-password {
        border-radius: 12px;
    }

    .pt {
        font-size: 16px;

        color: #001420;
        opacity: 0.3;
    }

    .alj {
        font-size: 0.16rem;
        color: rgba(0, 20, 32, 1);
        opacity: 0.8;
    }

    .phoneFather {
        position: relative;
        display: flex;
        align-items: center;

        .phoneCode {
            position: absolute;
            font-size: 18px;
            left: 29px;
        }
    }

    .grey {
        color: #7FC6FC !important;
    }

    .header {
        height: 0.8rem;
        display: flex;
        align-items: center;
        padding-top: 0.4rem;

        .header-icon {
            width: 0.6rem;
            height: 0.6rem;
            background: rgba(32, 32, 32, 1);
            border-radius: 50%;
        }

        .header-title {
            margin-left: 12px;
            font-size: 36px;         
            color: #001420;
            font-weight: 500;
        }
    }

    .loginContent {
        display: flex;
        width: 80%;
        height: 60%;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        .left {
            height: 100%;
            margin-left: 60px;
            h1 {
                font-size: 80px;
                color: #FFFFFF;
                font-weight: 800;
                margin-top: 30%;
                
            }
        }

        .loginFrom {
            width: 556px;
            opacity: 1;
            height: 657px;
            border-radius: 30px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0.04px 8px 0px rgba(0, 0, 0, 0.16);
            padding: 62px 78px 90px 78px;
            background: #fff;

            .from-title {
                opacity: 1;
                font-size: 40px;
                font-family: 'OPPOSans-M';
                color: #001420;
                text-align: left;
            }

            .textDescription {
                font-size: 16px;
                color: #001420;
                margin-bottom: 40px;

                .light {
                    color: #008EFA;
                }
            }

            .antdForm {
                .ant-form {
                    left: 0rem;

                    .spacing {
                        width: 1rem;
                        height: 0.8rem;
                    }

                }
            }

        }
    }

    .ant-form-item {
        margin: 0 0 20px;
    }

    .submitBtn {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        color: #008EFA;
        font-size: 16px;
    }

    .pswTips {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #001420;
        margin-top: -11px;
    }

    .ant-form-item-explain-error {
        text-align: right;
    }

    .ant-form-item-control {
        margin-bottom: 0px;
    }

    .ant-input-password {
        height: 64px;
    }

    .ant-input {
        &::placeholder {
            color: #001420;
        }
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        border: 2px solid red;
    }

    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
        border: 2px solid red;
    }

    .ant-input-affix-wrapper {
        padding: 4px 20px;
    }

    .ant-input-suffix {
        font-size: 25px;
    }


}