.order-type {
  width: 1240px;
  margin: 0 auto;
  margin-top: 30px;
}
.order-list{
  width: 1240px;
  margin: 0 auto;
  margin-top: 30px;
  .ant-list-split .ant-list-item{
      border-bottom:none;
  }
}
.type-item {
  width: 80px;
  height: 36px;
  background: #F7F7F7;
  border-radius: 18px;
  font-size: 14px;
  font-family: OPPOSans-B, OPPOSans;
  font-weight: normal;
}
.order-total{
  font-size: 16px;
  font-family: OPPOSans-M, OPPOSans;
  font-weight: normal;
  color: #001420;
  padding-left: 15px;
}
.type-item.active {
  color: #008efa;
  background: #DBEFFE;
  // opacity: 1;
}
.list-header {
  font-size: 16px;
  font-family: OPPOSans-M, OPPOSans;
  font-weight: normal;
  color: #001420;
 
}
.list-label {
  // font-family: "OPPOSans-M";
  color: #999999;
  background: #f6f6f6;
  height: 100px;
}

.list-content{
   height: 110px!important;
   color: #001420;
   margin-top: 20px!important;
}
.ant-list-item{
   
}
.list {
  width: 100%;
  height: 48px;
  margin: 10px 0;
}

.list-item {
  width: 100%;
  height: 100%;
  line-height: 48px;
  padding-left: 5px;
  font-size: 12px;
  font-family: OPPOSans-R, OPPOSans;
  font-weight: normal;
  display: flex;
  color: #001420;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 这里是超出几行省略 */
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-col-3{
    display: inline!important;
}
.item-msg {
  height: 130px;
}
.frist-box{
     display: flex;
     img{
          margin-right: 10px;
     }
}
.nft-img {
  width: 120px;
  height: 120px;
}

.order-box{
  .noStatusBack {
    position: relative;
    left:0px;
    top: 200px;
  }
} 
