.creatorUrl{
    width: 100%;
    height:13.5rem ;
    overflow-y: scroll;
    padding-bottom: 1rem;
    .creator-banner{
       width: 100%;
       height: 3.1rem;
       position: relative;
       margin-bottom: 0.8rem;
        .backgroud-Avatar{
           width: 100%;
           height: 3.1rem;
        }
        .creator-Avatar{
           width: 2.12rem;
           height: 2.12rem;
           position: absolute;
           left: 15%;
           bottom: -0.4rem;
        }
    }
    .creator-content{
          width: 76%;     
          margin:0 auto;
          padding-bottom: 1rem;
          position: relative;
         .creator-content-message{
            .title{
                display: flex;
                justify-content:space-between;
                margin-bottom: 0.1rem;
                .nickname{
                    font-size: 0.3rem;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                }
                .message-button{
                    width: 1.2rem;
                    height: 0.4rem;
                    text-align: center; 
                    line-height: 0.4rem;
                    background: #FFFFFF;
                    border-radius: 0.08rem;
                    border: 0.02rem solid #001420;
                    font-size: 0.16rem;
                    font-family: OPPOSans-B, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                }
            }
            .message-address{
                 margin-bottom: 0.1rem;
                 display: flex;
                 .message-address-content{
                    font-size: 0.16rem;
                    font-family: OPPOSans-R, OPPOSans;
                    font-weight: normal;
                    color: #000000;
                 }
                 .message-address-date{
                    font-size: 0.16rem;
                    font-family: OPPOSans-B, OPPOSans;
                    font-weight: normal;
                    color: #7F7F7F;
                    margin-left: 1rem;
                 }
            }
            .describe{
                font-size: 0.2rem;
                font-family: OPPOSans-R, OPPOSans;
                font-weight: normal;
                color: #000000;
            }
            .singleWeb{
                font-size: 0.2rem;
                font-family: OPPOSans-R, OPPOSans;
                font-weight: normal;
                color: #000000;
                margin-bottom: 0.5rem;
                margin-top: 0.2rem;
                display: flex;
                align-items: center;
                width: 75%;
                .singleWeb-left{
                     margin-right: 0.5rem;
                }
                .singleWeb-right{
                     display: flex;
                     align-items: center;
                }
            }
         }
         .header-table-box{
            width: 100%;
            height: 0.55rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom:0.01rem solid #eee; 
            .header-table{
                 display: flex;
                //  background: yellow;
                justify-content: space-between;
                 align-items: center;
                 margin-left: -0.3rem;
                 width: 4rem;
                 div{
                    text-align: center;    
                    height: 0.46rem;
                    padding: 0.06rem;
                    font-size: 0.2rem;
                    font-family: OPPOSans-B, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                    margin-top: 0.1rem;
                    font-size: 0.14rem;             
                 }
                 .active{
                    border-bottom: 0.03rem solid #00A2FD;
                 }
            }
         }
         .lable-form{
               display: flex;
               height: 0.6rem;
               justify-content: space-between;
               align-items: center;
               margin-top:0.3rem ;
               .lable-list{
                    display: flex;
                    align-items: center;
                    div{
                        width: 1.2rem;
                        height: 0.36rem;
                        background: #F7F7F7;
                        border-radius: 0.18rem;
                        text-align: center;
                        line-height: 0.36rem;
                        margin-right: 0.1rem;
                        font-size: 0.14rem;
                    }
                    .active{
                        color: #008EFA;
                    }
               }
              
         }
         .content {
            margin-top: 0.3rem;
            display: flex;
            flex-wrap: wrap;
            .card-my {
                width: 2.60rem;
                height: 3.97rem;
                overflow: hidden;
                margin-bottom: 0.3rem;
                box-shadow: 0rem 0.02rem 0.2rem 0rem rgba(0, 20, 32, 0.08);
                border-radius: 0.15rem 0.15rem 0.15rem 0.16rem;
                position: relative;
                margin-right: 0.3rem;
                .card-my-avatar {
                    width: 2.60rem;
                    height: 2.60rem;
                }

                .card-content-box {
                    height: 1.37rem;
                    padding: 0.2rem;
                    width: 100%;

                    .title {
                        width: 1.16rem;
                        height: 0.32rem;
                        font-size: 0.16rem;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #000000;
                        line-height: 0.32rem;
                        margin-top: 0rem;
                    }

                    .miaoshu {
                        font-size: 0.12rem;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #001420;
                    }

                    .card-content {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0.15rem;

                        .price {
                            height: 0.28rem;
                            font-size: 0.16rem;
                            font-family: 'OPPOSans-B, OPPOSans';
                            font-weight: 600;
                            color: #000000;
                            line-height: 0.28rem;
                            display: flex;

                            .price-symbol {
                                font-size: 0.14rem;
                            }
                        }

                        .house {
                            font-size: 0.14rem;
                            font-family: 'OPPOSans-M, OPPOSans';
                            font-weight: normal;
                            color: #000000;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .button {
                    position: absolute;
                    width: 1rem;
                    height: 0.4rem;
                    background: #008EFA;
                    border-radius: 0.08rem;
                    left: 0.8rem;
                    bottom: 1.8rem;
                    text-align: center;
                    line-height: 0.4rem;
                    font-size: 0.14rem;
                    font-family: 'OPPOSans-M, OPPOSans';
                    font-weight: normal;
                    color: #FFFFFF;
                    display: none;
                }

                .label {
                    position: absolute;
                    top: 0.2rem;
                    right: 0.2rem;
                    width: 0.6rem;
                    height: 0.28rem;
                    font-size: 0.12rem;
                    background: #070707;
                    border-radius: 0.22rem;
                    opacity: 0.6;
                    color: #fff;
                    text-align: center;
                    line-height: 0.28rem;
                    display: none;
                }

                .avatar-mask {
                    position: absolute;
                    z-index: 5;
                    top: 0rem;
                    right: 0rem;
                    width: 2.60rem;
                    height: 2.60rem;
                    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.17) 100%);
                    border-radius: 0.12rem 0.12rem 0rem 0rem;
                    opacity: 0.3;
                    display: none;
                }
            }

            .card-my:hover .card-content-box {
                background: rgba(0, 0, 0, 0.16);

            }

            .card-my:hover {
                bottom: 0.1rem;
            }

            .card-my:hover .avatar-mask {
                display: block;
            }

            .card-my:hover .label {
                display: block;
            }

            .card-my:hover .button {
                display: block;
            }
        }
        .list-card-header{
            width:98.75%;
            height: 0.48rem;
            background: #FCFCFC;
            padding-left: 0.2rem;
            display: flex;
            align-items: center;
            font-family: OPPOSans-R, OPPOSans;
            font-size: 0.14rem;
            font-weight: normal;
            color: #001420;
            margin-top: 0.4rem;
            .list-card-header-first{
                width: 20.5%;
            }
            .list-card-header-last{
                width: 55%;
            }
        }
        .list-card-detail{
            height: 1.75rem;
            padding-left: 0.2rem;
            padding-top: 0.2rem;
            display: flex;
            // padding: 0.3rem 0 ;
            .avate-meassage{
                  display: flex;
                  align-items: center;
                  width: 21%;
                  .avata-name{
                      margin-left: 0.2rem;
                      font-size: 0.16rem;
                  }
            }
            .pron-message{
                   display: flex;
                   align-items: center;
                   width: 14rem;
                   div{
                            font-size: 0.14rem;
                            font-family: OPPOSans-R, OPPOSans;
                            font-weight: normal;
                            color: #001420;
                   }
                   .one{
                        width: 69.5%;  
                        
                   }
                   .two{
                        width:14% ;
                   }
                   .tree{
                       width: 13%;
                   }
            }
        }
        .works{
              width: 100%;
              margin-right: 0.1rem;
              display: flex;
              position: absolute;
              left: -0.3rem;
              flex-wrap: wrap;
              li{ 
                width: 4.6rem;
                height: 3rem;
                background: #FFFFFF;
                box-shadow: 0rem 0.02rem 0.2rem 0rem rgba(0,20,32,0.08);
                border-radius: 0.15rem;
                border: 0.01rem solid #E4E4E4;
                margin-right: 0.157rem;
                margin-top: 0.15rem;
                overflow: hidden;
                position: relative;
               .backgroud-works{
                    width: 4.6rem;
                    height: 1.7rem;
                    margin-top: -0.02rem;
               }
               .works-label{
                   position: absolute;
                   bottom: 0.2rem;
                   left: 35%;
                   .touxiang-works{
                        width: 0.94rem;
                        height: 0.94rem;
                        border-radius: 50%;
                        border: 0.05rem solid #fff;
                   }
                   .collection-name{
                        font-size: 0.2rem;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                   }
                   .starnum{
                        font-size: 0.14rem;
                        width: 100%;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #000000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                   }
               }
          }
        }
    }
}