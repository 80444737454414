@import url(https://fonts.googleapis.com/css?family=Pacifico);
.home{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    header{
        height: 80px;
        display: flex; 
        justify-content: space-around;
        align-items: center;
        position:sticky;
        top:-0.01rem;
        z-index: 2;
        backdrop-filter: blur(0.2rem);
        transition: 0.5s ease;
    } 
    main{
         flex:1;
         a{
              text-decoration: none;
         }
         *::-webkit-scrollbar {
            width: 0px;
         }
        
    }
   //  footer{
   //      display: flex; 
   //      justify-content: space-around;
   //      align-items: center;
   //      background: #F1F1F1;
   //      height:3.6rem; 
   //      .footer-content{
   //           width: 80%;
   //           display: flex;
   //           justify-content: space-between;
   //           align-items: center;
   //           .footer-content-one{
   //               .logos{
   //                  width: 1.2rem;
   //                  height: 0.26rem;
   //                  margin-top: -0.6rem;
   //               }
   //               .common{
   //                  font-size: 0.44rem;
   //                  font-family: 'YouSheBiaoTiHei';
   //                  color: #202020;
   //                  font-weight: 900;
   //               }
   //               .left{
   //                  margin-top: -0.8rem;
   //               }
   //           }
   //           .footer-content-two{
   //               .content-two-title{
   //                  font-size: 0.2rem;
   //                  font-family: OPPOSans-M, OPPOSans;
   //                  font-weight: normal;
   //                  color: #001420;
   //                  margin-bottom: 0.3rem;
   //               }
   //               .describe{
   //                  font-size: 0.16rem;
   //                  font-family: OPPOSans-M, OPPOSans;
   //                  font-weight: normal;
   //                  color: #001420;
   //                  margin-bottom: 0.2rem;
   //               }
   //           }
   //           .footer-content-three{
   //               .content-three-title{
   //                  font-size: 0.2rem;
   //                  font-family: OPPOSans-M, OPPOSans;
   //                  font-weight: normal;
   //                  margin-bottom: 0.3rem;
   //                  color: #001420;
   //               }
   //               .describe{
   //                  font-size: 0.16rem;
   //                  font-family: OPPOSans-M, OPPOSans;
   //                  font-weight: normal;
   //                  color: #001420;
   //                  margin-bottom: 0.2rem;
   //               }
   //           }
   //           .footer-content-four{
   //               .content-four-title{
   //                  font-size: 0.2rem;
   //                  font-family: OPPOSans-M, OPPOSans;
   //                  font-weight: normal;
   //                  color: #001420;
   //                  margin-bottom: 0.3rem;
   //               }
   //               .describe{
   //                  font-size: 0.16rem;
   //                  font-family: OPPOSans-M, OPPOSans;
   //                  font-weight: normal;
   //                  color: #001420;
   //                  margin-bottom: 0.2rem;
   //               }
   //           }
   //      }
   //  }
}

.ant-message .anticon {
     top: -2px !important;
}
