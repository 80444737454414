@font-face {
  font-family: "OPPOSans-B";
  src: url("./assets/font/OPPOSans-B.ttf");
}

@font-face {
  font-family: "OPPOSans-H";
  src: url("./assets/font/OPPOSans-H.ttf");
}

@font-face {
  font-family: "OPPOSans-L";
  src: url("./assets/font/OPPOSans-L.ttf");
}

@font-face {
  font-family: "OPPOSans-M";
  src: url("./assets/font/OPPOSans-M.ttf");
}

@font-face {
  font-family: "OPPOSans-R";
  src: url("./assets/font/OPPOSans-R.ttf");
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
}
html,
body {
  font-family: 'OPPOSans-M';
}
#root {
  width: 100%;
  height: 100%;
  /* font-size: 16px; */
  font-weight: normal;
  /* font-family: "OPPOSans-M", "OPPOSans-R", "OPPOSans-L", "OPPOSans-H",
    "OPPOSans-B"; */
  box-sizing: border-box;
}

img {
  border: 0px;
}
