.set-box {
    width: 6rem;
    margin-top: 1.5rem;
    position: relative;

    .set-box-item {
        display: flex;
        height: 1rem;
        align-items: center;

        .ziti {
            font-size: 0.18rem;
            font-family: OPPOSans-M, OPPOSans;
            font-weight: normal;
            color: #001420;
        }

        .item-title {
            width: 20%;
        }

        .item-content {
            width: 60%;
            color: #8F989D;
            font-size: 0.16rem;
        }

        .item-last {
            width: 10%;
            font-size: 0.16rem;
            font-family: OPPOSans-M, OPPOSans;
            font-weight: normal;
            color: #008EFA;
        }

        .right-explain {
            font-size: 16px;
            color: #001420;
        }

        .auth-id {
            display: flex;
            align-items: center;
            img {
                margin-left: 6px;
            }
        }
        .auth-area {
            display: flex;
            justify-content: space-between;
            width: 100%;

        }
    }
}