.pay{
    width: 100%;
    background: #F9F9F9;
    .ant-input-number{
        width: 0.62rem;
        height: 0.3rem;
        background: rgba(216,216,216,0.25);
        border-radius: 0.08rem;
        border: 0.01rem solid rgba(151,151,151,0.42);
        display: flex;
        align-items: center;
    }
    .pay-content-box{
         width: 70%;
         margin: 0 auto;
         background: #fff;
         padding:0rem  0.3rem;
         padding-top: 0.8rem;
         .pay-title{
            font-size: 24px;
            font-family: OPPOSans-M, OPPOSans;
            font-weight: normal;
            color: #001420;
            padding-left: 0.2rem;
         }
         .attribute{
            display: flex;
            margin-top: 0.2rem;
            margin-bottom: 0.1rem;
            margin-left: 0.2rem;
            padding-bottom: 0.1rem;
            border-bottom: 2px solid #eee;
            div{
               font-size: 0.16rem;
               font-family: OPPOSans-M, OPPOSans;
               font-weight: normal;
               color: #001420;
            }
            .one{
                 width: 20%;
            }
            .two{
                 width: 40%;
            }
            .tree{
                 width:11%;
                 
            }
       }
       .list{
             width: 100%;
             height: 2rem;
             align-items: center;
             .list-card{
                    height: 1rem;
                    margin-top: 0.5rem;
                   .list-card-detail{
                       height: 1rem;
                       padding-left: 0.2rem;
                       display: flex;
                       .avate-meassage{
                             display: flex;
                             width: 25%;
                             .avata-name{
                                 margin-left: 0.2rem;
                                 font-size: 14px;
                                 font-family: 'OPPOSans-R, OPPOSans';
                                 font-weight: normal;
                                 color: #001420;
                             }
                       }
                       .pron-message{
                              display: flex;
                              width: 14rem;
                              div{
                                       font-size: 0.14rem;
                                       font-family: OPPOSans-R, OPPOSans;
                                       font-weight: normal;
                                       color: #001420;
                              }
                              .one{
                                   width: 51%;  
                                   font-size: 14px;
                                   font-family: 'OPPOSans-R, OPPOSans';
                                   font-weight: normal;
                                   color: #001420;
                              }
                              .two{
                                   width:14% ;
                                   font-size: 14px;
                                   font-family: 'OPPOSans-R, OPPOSans';
                                   font-weight: normal;
                                   color: #001420;
                              }
                              .tree{
                                  width: 13%;
                                  font-size: 14px;
                                  font-family: 'OPPOSans-R, OPPOSans';
                                  font-weight: normal;
                                  color: #001420;
                              }
                       }
                   }
             }
       }
         .recharge-points{
            width: 100%;
            padding-left: 0.2rem;
            // margin-top: 1.5rem;
            .points-two{
                 .points-label{
                   font-size: 0.24rem;
                   width: 2rem;
                   font-family: 'OPPOSans-M, OPPOSans';
                   font-weight: 500;
                   color: #001420;
                   margin-bottom: 0.2rem;
                 }
                 .two-box{
                       width: 89%;
                       display: flex;
                       flex-wrap: wrap;
                       .two-box-content{
                            width: 2.69rem;
                            height: 1.4rem;
                            background: #fff;
                            border-radius: 0.08rem;
                           margin-right: 0.16rem;
                           margin-bottom: 0.3rem;
                           border: 1px solid #bbb;
                       }
                       .two-box-content:nth-child(1){         
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                           .pay{
                                width: 0.93rem;
                                height: 0.36rem;
                           }
                           .border-image{
                                width: 100%;
                                height: 100%;
                                position: absolute;
                           }
                       }
                       .two-box-content:nth-child(2){         
                             display: flex;
                             align-items: center;
                             justify-content: center;
                             position: relative;
                            .pay-box{
                                width: 1.8rem;
                                display: flex;  
                                 
                                .left-pay{
                                    width: 0.36rem;
                                    height: 0.36rem;
                                }
                                .right-pay{
                                    width: 1rem;
                                    height: 0.5rem;               
                                    margin-left: 0.1rem;
                                    .two-title{
                                        font-size: 0.2rem;
                                        font-family: 'OPPOSans-M, OPPOSans';
                                        font-weight: normal;
                                        color: #001420;
                                    }
                                    .two-describe{
                                        font-size: 0.14rem;
                                        font-family: 'OPPOSans-M, OPPOSans';
                                        font-weight: normal;
                                        color: #bbb;
                                    }
                                }
                            }
                            .border-image{
                                width: 100%;
                                height: 100%;
                                position: absolute;
                            }
                       }
              
                       #active{
                           width: 2.69rem;
                           height: 1.4rem;
                           border-radius: 0.08rem;
                        //    border: 0.02rem solid #008EFA;
                       }
                 }
                 
            }
            
            .points-four{
                  display: flex;
                  align-items: center;
                  margin-top: 0.5rem;
                 .points-label{
                   font-size: 0.2rem;
                   font-family: OPPOSans-M, OPPOSans;
                   font-weight: normal;
                   color: #001420;
                   margin-right: 0.4rem;
                 }
                 .jine{
                   font-size: 0.4rem;
                   font-family: OPPOSans-B, OPPOSans;
                   font-weight: normal;
                   color: #008EFA;
                 }
                 .yuan{
                   font-size: 0.24rem;
                   font-family: OPPOSans-M, OPPOSans;
                   font-weight: normal;
                   color: #001420;
                 }
            }
            .pay-ok{
                width: 434px;
                height: 60px;
                border-radius: 8px;
                font-size: 22px;
                font-family: "OPPOSans-B, OPPOSans";
                font-weight: normal;
                color: #F9F9F9;
                top: -0.5rem;
                position: relative;
            }
        }
    }
}
