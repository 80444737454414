
.CreationIncome{
    width: 90%;
    position: absolute;
    left: 55px;
    top:120px;
    padding-bottom: 100px;
    .my-creation-Income{
         .title{
              display: flex;
              align-items: center;
              margin-top: -50px;
              .my-title{
                font-size: 20px;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #001420;  
              }
              .icon{
                     color: #eee;
                     transform: scale(0.2);
                     transform-origin: 10% 50%;
              }
         }
         .creation-card{
                width: 251px;
                height: 123px;
                background: #FCFCFC;
                border-radius: 8px;
                text-align: center;
                margin-bottom: 40px;
                display:flex;
                justify-content: center;
                align-items: center;
              .creation-two-box{
                .two-title{
                    font-size: 14px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                  }
                  .two-describe{
                    font-size: 24px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                  }
                  .two-money{
                    font-size: 12px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #93989A;
                  }
              }
             
         }
    }
    .shouyimingxi{
        font-size: 20px;
        font-family: 'OPPOSans-M, OPPOSans';
        font-weight: normal;
        color: #001420;
    }
    .list-card{
         height: 1400px;
        .list-card-title{
             display: flex;
             padding: 15px 0;
             border-bottom: 1px solid #eee;
             padding-left: 20px;
            li{
                font-size: 14px;
                font-family: 'OPPOSans-R, OPPOSans';
                font-weight: normal;
                color: #001420;
            }
            .one{
                  width: 28%;
            }
            .two{
                 width: 12%;
            }
            .tree{
                width: 8%;
            }
            .four{
                width: 12%;
            }
       }
       .ant-list-item{
            padding-left: 20px;
       }
       .list-card-box{
            display: flex;
            align-items: center;
            .element{
                font-size: 14px;
                font-family: OPPOSans-R, OPPOSans;
                font-weight: normal;
                color: #001420;
            }
            .one{
                display: flex;
                width: 20%;
                padding-right: 40px;
                span{
                    margin-left: 15px;
                }
            }
            .two{
                width: 13%;
            }
            .tree{
                width: 8%;
            }
            .four{
                width: 10%;
            }
       }
       .noStatusBack{
            position: relative;
            left: 0;
            top:300px;
        }
    }
  
   
}
 