.HomePage{
    width: 100%;
    position: absolute;
    left: 0px;
    top:-59px;
    .creator-banner{
        width: 100%;
        height: 146px;
        position: relative;
        margin-bottom: 80px;
         .backgroud-Avatar{
            width: 100%;
            height: 146px;
            margin-top: -17px;
         }
         .creator-Avatar{
            width: 120px;
            height: 120px;
            box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.1);
            border: 4px solid #FFFFFF;
            position: absolute;
            left: 5%;
            bottom: -40px;
         }
         .edit-message{
            font-size: 14px;
            font-family: 'OPPOSans-M, OPPOSans';
            font-weight: normal;
            color: #fff;
            display: flex;
            align-items: center;
            position: absolute;
            left: 45%;
            top:50%;
            .anticon svg{
                width: 16px;
                height: 16px;
           }
         }
         .edit-message-back{
            font-size: 16px;
            font-family: 'OPPOSans-M, OPPOSans';
            font-weight: normal;
            color: #fff;
            display: flex;
            align-items: center;
            position: absolute;
            left: 45%;
            top:40%;
            display: none;
            pointer-events: none;
            .anticon svg{
                width: 16px;
                height: 16px;
           }
         }
         
     }
     .creator-banner:hover{
        .edit-message-back{
               display: block;
        }
     }
     .creator-content{
        width: 90%;
        margin:0 auto;
        padding-bottom: 100px;
        position: relative;
       .creator-content-message{
          .title{
              display: flex;
              justify-content:space-between;
              margin-bottom: 10px;
              span{
                  font-size: 30px;
                  font-family: OPPOSans-M, OPPOSans;
                  font-weight: normal;
                  color: #001420;
              }
              .edit-message{
                font-size: 14px;
                font-family: 'OPPOSans-M, OPPOSans';
                font-weight: normal;
                color: #202020;
                display: flex;
                align-items: center;
                .anticon svg{
                    width: 16px;
                    height: 16px;
               }
              }
          }
          .message-address{
               margin-bottom: 10px;
               width: 100%;
               display: flex;
               justify-content: space-between;
               b{
                    font-size: 14px;
                    font-family: OPPOSans-R, OPPOSans;
                    font-weight: normal;
                    color: #000000;
               }
               span{
                    font-size: 14px;
                    font-family: OPPOSans-R, OPPOSans;
                    font-weight: normal;
                    color: #93989A;
                    margin-right: 40px;
               }
          }
          .describe{
            font-size: 14px;
            font-family: 'OPPOSans-R, OPPOSans';
            font-weight: normal;
            color: #000000;
          }
          .singleWeb{
            font-size: 20px;
            font-family: 'OPPOSans-R, OPPOSans';
            font-weight: normal;
            color: #000000;
            margin-bottom: 50px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            .singleWeb-left{
                 margin-right: 50px;
            }
            .singleWeb-right{
                 display: flex;
                 align-items: center;
                 font-size: 16px;
                 font-family: OPPOSans-M, OPPOSans;
                 font-weight: normal;
                 color: #000000;
            }
        }
          .worksAdmin-box{
                 width: 100%;
                .worksAdmin-title{
                     width: 100%;
                     display: flex;
                     align-items: center;
                     justify-content: space-between;       
                     padding: 10px 0px;
                     border-bottom: 2px solid #eee;
                     .title-left{
                        font-size: 24px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                     }
                     .title-right{
                        font-size: 16px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #008EFA;
                     }
                }
                .dl{ 
                    padding-top: 20px;
                    padding-left: 30px;
                    padding-bottom: 60px;
                    display: flex;
                    .works-Avatar{
                        width: 150px;
                        height: 200px;
                    }
                    .works-content-box{
                        margin-left: 20px;
                        .title{
                            font-size: 16px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #001420;
                        }
                        .works-eye-love{
                             display: flex;
                             justify-content: space-between;
                            .eye{
                                display: flex;
                                align-items: center;
                                font-size: 12px;
                                font-family: OPPOSans-M, OPPOSans;
                                font-weight: normal;
                                color: #001420;
                                margin-left: -30px;
                            }
                            .love{
                                display: flex;
                                align-items: center;
                                font-size: 12px;
                                font-family: OPPOSans-M, OPPOSans;
                                font-weight: normal;
                                color: #001420;
                            }
                            span{
                                font-size: 12px;
                                font-family: OPPOSans-M, OPPOSans;
                                font-weight: normal;
                                color: #bbb;
                                margin-left: 30px;
                            }
                        }
                        .works-count-box{
                            display: flex;
                            .works-status-count{
                                font-size: 12px;
                                margin-top: 10px;
                                font-family: 'OPPOSans-M, OPPOSans';
                                font-weight: normal;
                                color: #bbb;
                                margin-right: 10px;
                            }
                            .works-count{
                                font-size: 12px;
                                margin-top: 10px;
                                font-family: 'OPPOSans-M, OPPOSans';
                                font-weight: normal;
                                color: #bbb;
                           }
                        }
                      
                        .footer-status{
                            display: flex;
                            margin-top: 95px;
                            .status-left{
                               font-size: 16px;
                               font-family: 'OPPOSans-M, OPPOSans';
                               font-weight: normal;
                               color: #bbb;
                            }
                            .status-right{
                               font-size: 16px;
                               font-family: 'OPPOSans-M, OPPOSans';
                               font-weight: normal;
                               color: #bbb;
                               margin-left: 30px;
                            }
                      }
                    }
                    
             }
             .noStatus-box{
                   width: 100%;
                   height: 200px;
                   display: block;
                   background: #FCFCFC;
                   color: #bbb;
                   position: relative;
                   .noStatus-content{
                        text-align: center;
                        width: 300px;
                        position: absolute;
                        left: 38%;
                        top:32%;
                        .add-icon{
                             font-size: 30px;
                             width: 40px;
                             height: 40px;
                             background: #ddd;
                             text-align: center;
                             line-height: 40px;
                             border-radius: 50%;
                             margin: 0 auto;
                        }
                        .add-describe{
                              margin-top: 10px;
                              font-size: 16px;
                        }
                   }
             }
          }
          .WorksSeries-box{
            width: 100%;
            .worksAdmin-title{
                 width: 100%;
                 display: flex;
                 align-items: center;
                 justify-content: space-between;       
                 padding: 10px 0px;
                 border-bottom: 2px solid #eee;
                 .title-left{
                    font-size: 24px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                 }
                 .title-right{
                    font-size: 16px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #008EFA;
                 }
            }
            .dl{ 
                padding-top: 20px;
                padding-left: 30px;
                padding-bottom: 60px;
                display: flex;
                .WorksSeries-Avatar{
                    width: 320px;
                    height: 240px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 20px 0px rgba(0,20,32,0.08);
                    border-radius: 4px;
                    border: 1px solid #E4E4E4;
                }
                .works-content-box{
                    margin-left: 30px;
                    .title{
                        font-size: 16px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                    }
                    .eye-love{
                         display: flex;
                         margin-top: 20px;
                        .eye{
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #001420;
                            margin-left: -30px;
                        }
                        .love{
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #001420;
                        }
                        span{
                            font-size: 12px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #bbb;
                            margin-left: 30px;
                        }
                    }
                    .wokrs-count{
                        font-size: 12px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #93989A;
                        margin-top: 20px;
                    }
                    .operation{
                          margin-top: 115px;
                          display: flex;
                          align-items: center;
                          a{
                            font-size: 16px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #bbb;
                            margin-right: 40px;
                          }
                    }
                }
                
             }
             .noStatus-box{
                width: 100%;
                height: 200px;
                display: block;
                background: #FCFCFC;
                color: #bbb;
                position: relative;
                .noStatus-content{
                     text-align: center;
                     width: 300px;
                     position: absolute;
                     left: 38%;
                     top:32%;
                     .add-icon{
                          font-size: 30px;
                          width: 40px;
                          height: 40px;
                          background: #ddd;
                          text-align: center;
                          line-height: 40px;
                          border-radius: 50%;
                          margin: 0 auto;
                     }
                     .add-describe{
                           margin-top: 10px;
                           font-size: 16px;
                     }
                }
          }
          }
          .ok-status{
            .CreationIncome-box{
                width: 100%;
                .worksAdmin-title{
                 width: 100%;
                 display: flex;
                 align-items: center;
                 justify-content: space-between;       
                 padding: 10px 0px;
                 border-bottom: 2px solid #eee;
                 .title-left{
                    font-size: 24px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                 }
                 .title-right{
                    font-size: 16px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #008EFA;
                 }
                }
                .p{
                         font-size: 14px;
                         font-family: OPPOSans-R, OPPOSans;
                         font-weight: normal;
                         color: #001420;
                         padding: 40px 0;
                }
            }
            .FansPage-box{
                width: 100%;
                .worksAdmin-title{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;       
                    padding: 10px 0px;
                    border-bottom: 2px solid #eee;
                    .title-left{
                        font-size: 24px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #001420;
                    }
                    .title-right{
                        font-size: 16px;
                        font-family: OPPOSans-M, OPPOSans;
                        font-weight: normal;
                        color: #008EFA;
                    }
                    }
                .FansPage-title{
                        display: flex;
                        width: 100%;
                        align-items: center;
                        padding: 20px;
                        span{
                            font-size: 14px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #999;
                        }
                        .title-one{
                            width: 30%;
                        }
                        .title-two{
                            width: 35%;
                        }
                        .title-two{
                            font-size: 14px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #999;
                            width: 45%;
                        }
                }
                .FansPage-list{
                        display: flex;
                        align-items: center;
                        height: 100px;
                        .nickName{ 
                            width: 31%;
                            display: flex;
                            align-items: center;
                            padding-left: 20px;
                            .FansPage-Avatar{
                                width: 40px;
                                height: 40px;
                            }
                            .zhiyu{
                                font-size: 14px;
                                font-family: 'OPPOSans-R, OPPOSans';
                                font-weight: normal;
                                color: #001420;
                                margin-left: 10px;
                                width: 150px;
                                white-space:nowrap;        // 强制一行显示
                                overflow:hidden;          // 超出隐藏
                                text-overflow:ellipsis;   // 省略号     
                            }
                        }
                        .jianjie{
                            width:43%;
                            font-size: 14px;
                            font-family: 'OPPOSans-R, OPPOSans';
                            font-weight: normal;
                            color: #001420;
                            padding-right: 60px;
                            white-space:nowrap;        // 强制一行显示
                            overflow:hidden;          // 超出隐藏
                            text-overflow:ellipsis;   // 省略号   
                        }
                        .gzsj{
                            font-size: 14px;
                            font-family: 'OPPOSans-R, OPPOSans';
                            font-weight: normal;
                            color: #001420;
                        }
                }
                .no-fans{
                    color: #bbb;
                    margin-top: 20px;
                    font-size: 16px;
                }
            }
          }
          .noStatusBack{
            position: relative;
            left:0;
            top:100px;
            span{
                font-size: 14px;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #8f8f8f;
            }
            button{
                width: 160px;
                height: 48px;
                background: #008EFA;
                border-radius: 8px;
                span{
                    color: #fff;
                }
            }
           }
       }
  }
}