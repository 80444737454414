.message-card{
     width: 100%;
     margin-left: 0.3rem;
     .item-row{
          display: flex;
          align-items: center;
         justify-content: space-between;
         .left-col{
              display: flex;
              align-items: center;
              img{ 
                width: 0.6rem;
                height: 0.6rem;
              }
              .left-col-content{
                   margin-left: 0.2rem;
                   .title{
                        font-size: 16px;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #001420;
                   }
                   .discribe{
                        font-size: 14px;
                        font-family: OPPOSans-R, OPPOSans;
                        font-weight: normal;
                        color: #bbb;
                   }
              }
         } 
         .right-col{

         }
     }
}