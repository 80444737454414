.from-message {
    position: absolute;
    left: -0.29rem;
    top: 1.5rem;
    .ant-form-item{
        margin-bottom: 0.5rem;
    }
    .logo-box {
        background: #1C1618;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
    }
    .fic-box{
        background: red;
        width: 5.4rem;
        height: 2.4rem;
        background: #fff;
        box-shadow: 0rem 0.04rem 0.2rem 0rem rgba(0, 0, 0, 0.05);
        img{
              width: 100%;
              height: 100%;
        }
    }
    .submit{
        width: 2.5rem;
        height: 0.5rem;
        background: #008EFA;
        border-radius: 0.08rem;
    }
    .ant-col-8 {
        margin-right: 0.4rem;
        width: 0.8rem;
        // height: 0.26rem;
        font-size: 0.3rem; 
        font-weight: normal;
        color: #001420;
        // line-height: 0.26rem;
    }
    .ant-form-item-label>label{
        width: 1.5rem;
        height: 0.26rem;
        font-size: 0.2rem;
        font-family: 'OPPOSans-M';
        font-weight: normal;
        color: #001420;
        line-height: 0.26rem;
    }
}