
.EditPhone{
    .test-box{
        width: 100%;
        height: 5rem;
        position: absolute;
        background: #fff;
        left:0.5rem;
        top:0.1rem;
        .test-back{
             display: flex;
             align-items: center;
             font-size: 0.16rem;
             font-family: 'OPPOSans-M, OPPOSans';
             font-weight: normal;
             color: #001420;
             margin-bottom: 0.5rem;
             transform:scale(1.5);
             transform-origin: 0% 0%;
        }
        .test-title{
             font-size: 0.24rem;
             font-family: 'OPPOSans-M, OPPOSans';
             font-weight: normal;
             color: #001420;
             margin-bottom: 0.2rem;
        }
        .message-test-box{
             width: 5rem;
             height: 0.55rem;
             border-radius: 0.08rem;
             .message-test{
                 width: 3.6rem;
                 height: 0.55rem;
                 background: #F0F1F4;
                 border-radius: 0.08rem;
                 .ant-input{
                    background: #F0F1F4;
                    padding-left: 0.2rem;
                 }
             }
             .link-button{
               //     position: absolute;
               //     right: 0rem;
               //     top:0.85rem;
             }
        } 
        .button-ok{
             width: 1.2rem;
             height: 0.5rem;
             border-radius: 0.08rem;
        }
    }
}
 