.creator{
    width: 100%;
    height:1350px ;
    overflow-y: scroll;
    padding-bottom: 100px;
    .creator-banner{
       width: 100%;
       height: 310px;
       position: relative;
       margin-bottom: 80px;
        .backgroud-Avatar{
           width: 100%;
           height: 310px;
        }
        .creator-Avatar{
           width: 200px;
           height: 200px;
           border: 8px solid #FFFFFF;
           position: absolute;
           left: 350px;
           bottom: -40px;
        }
    }
    .creator-content{
          width:1240px;     
          margin:0 auto;
          padding-bottom: 100px;
          position: relative;
         .creator-content-message{
            .title{
                display: flex;
                justify-content:space-between;
                margin-bottom: 10px;
                .nickname{
                    font-size: 30px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                }
                .house{
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: #000000;
                    .anticon svg{
                          width: 30px;
                          height: 20px;
                   }
               }
            }
            .message-address{
                 margin-bottom: 10px;
                 display: flex;
                 .message-address-content{
                    font-size: 16px;
                    font-family: OPPOSans-R, OPPOSans;
                    font-weight: normal;
                    color: #000000;
                 }
                 .message-address-date{
                    font-size: 16px;
                    font-family: OPPOSans-B, OPPOSans;
                    font-weight: normal;
                    color: #7F7F7F;
                    margin-left: 100px;
                 }
            }
            .describe{
                font-size: 20px;
                font-family: OPPOSans-R, OPPOSans;
                font-weight: normal;
                color: #000000;
            }
            .singleWeb{
                font-size: 20px;
                font-family: OPPOSans-R, OPPOSans;
                font-weight: normal;
                color: #000000;
                margin-bottom: 50px;
                margin-top: 20px;
                display: flex;
                align-items: center;
                width: 75%;
                .singleWeb-left{
                     margin-right: 50px;
                }
                .singleWeb-right{
                     display: flex;
                     align-items: center;
                }
            }
         }
         .header-table-box{
            width: 100%;
            height: 55px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom:1px solid #eee; 
            .header-table{
                 display: flex;
                justify-content: space-between;
                 align-items: center;
                 width: 400px;
                 div{
                    text-align: center;    
                    height: 46px;
                    padding: 6px;
                    font-size: 20px;
                    font-family: OPPOSans-B, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                    margin-top: 10px;
                    font-size: 14px;             
                 }
                 .active{
                    border-bottom: 3px solid #00A2FD;
                 }
            }
         }
         .lable-form{
               display: flex;
               height: 60px;
               justify-content: space-between;
               align-items: center;
               margin-top:30px ;
               .lable-list{
                    display: flex;
                    align-items: center;
                    div{
                        width: 120px;
                        height: 36px;
                        background: #F7F7F7;
                        border-radius: 18px;
                        text-align: center;
                        line-height: 36px;
                        margin-right: 10px;
                        font-size: 14px;
                    }
                    .active{
                        color: #008EFA;
                    }
               }
              
         }
         .content {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            .noStatusBack{
                 position: absolute;
                 left: 50%;
                 top:100px;
            }
            .card-my {
                width: 260px;
                height: 397px;
                overflow: hidden;
                margin-bottom: 30px;
                box-shadow: 0px 2px 20px 0px rgba(0, 20, 32, 0.08);
                border-radius: 15px 15px 15px 16px;
                position: relative;
                margin-right: 30px;
                .card-my-avatar {
                    width: 260px;
                    height: 260px;
                }

                .card-content-box {
                    height: 137px;
                    padding: 20px;
                    width: 100%;
                    .title {
                        width: 116px;
                        height: 32px;
                        font-size: 16px;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #000000;
                        line-height: 32px;
                        margin-top: 0px;
                    }

                    .miaoshu {
                        font-size: 12px;
                        font-family: 'OPPOSans-M, OPPOSans';
                        font-weight: normal;
                        color: #001420;
                    }

                    .card-content {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 15px;

                        .price {
                            height: 28px;
                            font-size: 16px;
                            font-family: 'OPPOSans-B, OPPOSans';
                            font-weight: 600;
                            color: #000000;
                            line-height: 28px;
                            display: flex;

                            .price-symbol {
                                font-size: 14px;
                            }
                        }

                        .house {
                            font-size: 14px;
                            font-family: 'OPPOSans-M, OPPOSans';
                            font-weight: normal;
                            color: #000000;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .button {
                    position: absolute;
                    width: 100px;
                    height: 40px;
                    background: #008EFA;
                    border-radius: 8px;
                    left: 80px;
                    bottom: 180px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 14px;
                    font-family: 'OPPOSans-M, OPPOSans';
                    font-weight: normal;
                    color: #FFFFFF;
                    display: none;
                }

                .label {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 60px;
                    height: 28px;
                    font-size: 12px;
                    background: #070707;
                    border-radius: 22px;
                    opacity: 0.6;
                    color: #fff;
                    text-align: center;
                    line-height: 28px;
                    display: none;
                }

                .avatar-mask {
                    position: absolute;
                    z-index: 5;
                    top: 0px;
                    right: 0px;
                    width: 260px;
                    height: 260px;
                    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.17) 100%);
                    border-radius: 12px 12px 0px 0px;
                    opacity: 0.3;
                    display: none;
                }
            }

            .card-my:hover .card-content-box {
                background: rgba(0, 0, 0, 0.16);

            }

            .card-my:hover {
                bottom: 10px;
            }

            .card-my:hover .avatar-mask {
                display: block;
            }

            .card-my:hover .label {
                display: block;
            }

            .card-my:hover .button {
                display: block;
            }
        }
        .list-card-header{
            width:98.75%;
            height: 48px;
            background: #FCFCFC;
            padding-left: 20px;
            display: flex;
            align-items: center;
            font-family: OPPOSans-R, OPPOSans;
            font-size: 14px;
            font-weight: normal;
            color: #001420;
            margin-top: 40px;
            .list-card-header-first{
                width: 20.5%;
            }
            .list-card-header-last{
                width: 55%;
            }
        }
        .list-card-detail{
            height: 175px;
            padding-left: 20px;
            padding-top: 20px;
            display: flex;
            // padding: 30px 0 ;
            .avate-meassage{
                  display: flex;
                  align-items: center;
                  width: 21%;
                  .avata-name{
                      margin-left: 20px;
                      font-size: 16px;
                  }
            }
            .pron-message{
                   display: flex;
                   align-items: center;
                   width: 1400px;
                   div{
                            font-size: 14px;
                            font-family: OPPOSans-R, OPPOSans;
                            font-weight: normal;
                            color: #001420;
                   }
                   .one{
                        width: 69.5%;  
                        
                   }
                   .two{
                        width:14% ;
                   }
                   .tree{
                       width: 13%;
                   }
            }
        }
        .works{
              width: 100%;
              margin-right: 10px;
              display: flex;
              flex-wrap: wrap;
              position: relative;
              .noStatusBack{
                   position: absolute;
                   left: 50%;
                   top:200px;
              }
              li{ 
                    width: 392px;
                    height: 300px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 20px 0px rgba(0,20,32,0.08);
                    border-radius: 15px;
                    border: 1px solid #E4E4E4;
                    margin-right: 15.7px;
                    margin-top: 15px;
                    overflow: hidden;
                    position: relative;
                   .backgroud-works{
                    width: 392px;
                        height: 170px;
                        margin-top: -2px;
                   }
                   .works-label{
                       position: absolute;
                       bottom: 20px;
                       left: 35%;
                       .touxiang-works{
                            width: 94px;
                            height: 94px;
                            border-radius: 50%;
                            border: 5px solid #fff;
                       }
                       .collection-name{
                            font-size: 20px;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #001420;
                       }
                       .starnum{
                            font-size: 14px;
                            width: 100%;
                            font-family: OPPOSans-M, OPPOSans;
                            font-weight: normal;
                            color: #000000;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                       }
                   }
              }
        }
        .noStatusBack{
            position: relative;
              top:100px;
        }
    }
}