.UploadHomeImage{
       width: 100%;    
       height: 146px;
       .backgroud-top_pic{
            width: 100%;
            height: 146px;
            margin-top: -18px;
            background: #EBEDEF;
       }
       .ant-upload.ant-upload-drag{
        width: 100%;
        height: 146px;
       } 
}  