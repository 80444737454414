.box-card {
  width: 286px;
  height: 417px;
  background: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 20, 32, 0.08);
  border-radius: 15px 15px 15px 15px;
  border: 1px solid #e4e4e4;
  overflow: hidden;
  margin: 0 30px 30px 0;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

.top,
.bottom {
  width: 100%;
  .sell-describe{
      color: #bbb;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
}

.top {
  height: 286px;
  position: relative;
}

.top-img {
  border: 0px;
  height: 286px;
  width: 100%;
}
.ant-image{
  width: 100%;
}

.collection-img {
  width: 100%;
  height: 100%;
}

.bottom {
  height: calc(100% - 286px);
  padding: 20px;
  justify-content: space-between;
}

.label {
  color: #999999;
}

.serios-card {
  width: 392px;
  height: 300px;
  border-radius: 12px;

  .top {
    height: 160px;
    position: relative;
  }

  .serios-img {
    width: 392px;
    height: 160px;
  }

  .bottom {
    height: calc(100% - 160px);
    justify-content: flex-end;
  }

  .avator {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    overflow: hidden;
    border: 6px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
  }

  .avator-img {
    width: 100%;
    height: 100%;
  }
}

.creator-card {
  width: 286px;
  height: 370px;
  .creator-img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    margin: 0 auto;
  }

  .top {
    height: auto;
    margin: 15px 0;
  }
}
