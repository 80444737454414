.footer-page {
    width: 100%;
    padding-top: 42px;
    padding-bottom: 50px;
    background: #F8FAFD;
    display: flex;
    justify-content: center;

    .footer-content-one {

        color: #001420;
        font-size: 12px;

        .logos {
            margin-bottom: 26px;
        }

        .left {
            margin-bottom: 26px;

        }

        .record-mark {}
    }

    .footer-content {

        display: flex;
        justify-content:space-between;
        width: 1240px;

        .right {
            // margin-left: 383px;
            display: flex;
            justify-content: space-around;

            .main-title {
                font-size: 16px;
                color: #001420;
                margin-bottom: 28px;
            }

            .describe {
                color: #001420;
                font-size: 12px;
            }

            .footer-content-two {
                margin-right: 148px;
            }
            .footer-content-three {
                margin-right: 170px;

            }
        }
    }
}