.f-r {
  display: flex;
  flex-direction: row;
}

.f-c-c {
  align-items: center;
  justify-content: center;
}

.f-c-e {
  align-items: center;
  justify-content: flex-end;
}

.f-c-b {
  align-items: center;
  justify-content: space-between;
}

.f-c {
  display: flex;
  flex-direction: column;
}
