.createNft {
    background: #F9F9F9;
    height: 1300px;
    .space {
        height: 16px;
    }
    .father-content {
        height: 100%;
        width:1245px;
        display: flex;
        margin: 0 auto;
        .left-card {
            width: 270px;
            background: #fff;
            .create-button-box {
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                border-bottom: 1px solid #eee;
                .create-button {
                    width: 200px;
                    height: 60px;
                    background: #008EFA;
                    border-radius: 8px;
                }
            }
            .setUp-button {
                margin-top: 30px;
                margin-bottom: 50px;
                margin-left: 45px;
                height: 40px;
                font-size: 20px;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #001420;
                line-height: 40px;
            }
            .left-list-title {
                font-weight: normal;
                a {
                    display: block;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    font-size: 18px;
                    text-decoration: none;
                    color: #bbb;
                    display: flex;
                    align-items: center;
                    padding-left: 45px;
                }

                .creation-icon {
                    width: 18px;
                    height: 20px;
                }

                a:nth-child(1) {
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-home.svg);
                        background-size: 100% 100%;
                    }
                }

                a:nth-child(1):focus {
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-home-light.svg);
                        background-size: 100% 100%;
                    }
                }
                a:nth-child(2) {
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-sereise.svg);
                        background-size: 100% 100%;
                    }
                }

                a:nth-child(2):focus{
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-sereise-light.svg);
                        background-size: 100% 100%;
                    }
                }
                a:nth-child(3) {
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-works.svg);
                        background-size: 100% 100%;
                    }
                }

                a:nth-child(3):focus{
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-works-light.svg);
                        background-size: 100% 100%;
                    }
                }
                a:nth-child(4) {
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-wallet.svg);
                        background-size: 100% 100%;
                    }
                }

                a:nth-child(4):focus {
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-wallet-light.svg);
                        background-size: 100% 100%;
                    }
                }
                a:nth-child(5) {
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-fans.svg);
                        background-size: 100% 100%;
                    }
                }

                a:nth-child(5):focus {
                    .creation-icon {
                        background: url(../../assets/img/creation/creation-fans-light.svg);
                        background-size: 100% 100%;
                    }
                }
                .active {
                    background: #F3F7FB;
                    border-right: 4px solid #008EFA;
                    color: #001420;
                }
            }
        }

        .right-card {
            width: 970px;
            background: #fff;
            margin-left: 2px;
            overflow-y: scroll;
            .setup-form {
                width: 100%;
                height: 500px;
                margin-top: 60px;
                position: relative;
                .title-form {
                    position: absolute;
                    left: 55px;
                    top: 50px;
                    width: 96px;
                    height: 32px;
                    font-size: 24px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                    line-height: 32px;
                }
                .recharge-points {
                    width: 100%;
                    position: absolute;
                    top: 120px;
                    left: 100px;
                }
            }
        }
    }
    
    
}

.createNft-popover{
    .ant-popover-content{
        width: 270px;
        .ant-popover-inner-content{
            // padding: 12px 36px;
            .prover-content-box {
                width: 270px;
                text-align: center;
                .prover-selecet {
                    width: 235px;
                    height: 60px;
                    border-radius: 6px;
                    line-height: 60px;
                    font-size: 16px;
                    font-family: OPPOSans-M, OPPOSans;
                    font-weight: normal;
                    color: #001420;
                }
                .prover-selecet:hover {
                    background: #F3F7FB;
                    border-radius: 3px;
                }
            }
        } 
    }
}



// @media only screen and (min-height:16rem) {
//     .createNft{
//          height: 17rem;
//     }
// }